.nm-material-theme .mat-tab-group {
  .mat-tab-label {
    opacity: 1;
    border-right: 1px solid #707070;
    font-size: 12px;
    height: 32px;
    font-weight: 600;

    &:first-child {
      border-left: 1px solid #707070;
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      background-color: transparent;
    }

    &.mat-tab-disabled:hover {
      opacity: 1;
    }
  }

  &.mat-cyan .mat-tab-label-active {
    color: #00b9b5;
  }

  &.mat-primary .mat-tab-label-active {
    color: #bd2e7e;
  }

  &.mat-accent .mat-tab-label-active {
    color: #bd2e7e;
  }

  &.mat-warn .mat-tab-label-active {
    color: #e25874;
  }

  .mat-tab-header {
    border: 0;
    margin: 0 0 16px;
  }

  .mat-ink-bar {
    display: none;
  }
}
