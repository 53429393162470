.upln-form-field {
  border: 1px #c6c6c6 solid;
  border-radius: 5px;
  padding: 0 10px;
}

.r-link {
  text-decoration: none;
  color: inherit;
}
.r-link:hover {
  text-decoration: none;
  color: inherit;
}
.r-link:visited {
  color: inherit;
}

.r-button {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: 0;
}

.r-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.r-paragraph {
  margin-top: 0;
  margin-bottom: 0;
}

.r-body {
  margin: 0;
}

.r-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.pad-l-0 {
  padding-left: 0;
}

.pad-r-0 {
  padding-right: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-move:hover {
  cursor: move !important;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.upln-form-field {
  border: 1px #c6c6c6 solid;
  border-radius: 5px;
  padding: 0 10px;
}

.mat-custom-form-field .mat-form-field-wrapper {
  padding-bottom: 0px;
}
.mat-custom-form-field .mat-form-field-underline {
  bottom: 0px;
  height: 2px;
  background-color: #ebebeb;
}
.mat-custom-form-field .mat-form-field-underline .mat-form-field-ripple {
  height: 2px;
}
.mat-custom-form-field .mat-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mat-custom-form-field .mat-form-field-infix {
  border-top-width: 0;
}
.mat-custom-form-field .mat-form-field-subscript-wrapper {
  margin-top: 5px;
  top: initial;
}
.mat-custom-form-field.big-field {
  width: 300px;
}
.mat-custom-form-field.big-field .mat-form-field-underline {
  background-color: #757575;
}
.mat-custom-form-field.big-field .mat-input-element {
  font-weight: 600;
  color: #000;
  transition: color 250ms;
}
.mat-custom-form-field.big-field .mat-form-field-label {
  font-weight: 600;
}
.mat-custom-form-field.big-field .mat-form-field-subscript-wrapper {
  font-size: 50%;
}
.mat-custom-form-field.m-chips-list .mat-chip-list-wrapper {
  min-height: 32px;
  align-items: center;
}
.mat-custom-form-field.m-chips-list .mat-form-field-label {
  top: 25px;
}
.mat-custom-form-field.m-off-appearance .mat-form-field-underline {
  visibility: hidden;
}
.mat-custom-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #f44336;
}
.mat-custom-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336 !important;
}

.admin-custom-mat-form-field {
  width: 100%;
}
.admin-custom-mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}
.admin-custom-mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0;
}
.admin-custom-mat-form-field.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  top: 100%;
}
.admin-custom-mat-form-field .mat-form-field-wrapper {
  width: 100%;
}
.admin-custom-mat-form-field .mat-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
}
.admin-custom-mat-form-field .mat-form-field-underline {
  display: none;
}
.admin-custom-mat-form-field .mat-select {
  display: inline-flex;
  align-items: center;
}
.admin-custom-mat-form-field .mat-select-trigger {
  flex-grow: 1;
}
.admin-custom-mat-form-field .admin-field {
  flex-grow: 1;
  min-height: 36px;
  padding: 8px 17px;
  border: 1px solid #707070;
  background-color: #fff;
  color: rgb(69, 69, 69);
  font-size: 12px;
}
.admin-custom-mat-form-field .mat-datepicker-toggle {
  position: absolute;
  right: 0;
  top: -2px;
}
.admin-custom-mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
  padding-left: 10px;
  padding-top: 2px;
}

.input-text-field {
  width: 100%;
  padding: 8px 15px;
  border: 2px solid rgb(206, 212, 218);
  font-size: 14px;
}

.upln-form-field-box {
  position: relative;
  height: 37px;
}

.upln-form-field {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.upln-form-field:focus {
  outline: none;
  border: 1px solid #bd2e7e;
}
.upln-form-field.upln-form-field-select {
  background-color: transparent;
  -webkit-appearance: none;
  padding-right: 20px;
  position: relative;
  background-image: url("../assets/images/onboarding/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center right 10px;
}

#lpb-button, .b-button.lpb-button-warning, .b-button.lpb-button-danger, .b-button.lpb-button-custom-color, .b-button.lpb-button-empty-background-warning, .b-button.lpb-button-empty-background-danger, .b-button.lpb-button-empty-background, .b-button.lpb-button-main, .b-button.lpb-button-ghost, .b-button.lpb-button-default {
  color: #757575;
  cursor: pointer;
  margin: 0 10px;
  outline: none;
  border-radius: 5px;
  box-shadow: none;
  min-height: 33px;
}

.b-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  min-width: 130px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  transition: opacity 250ms;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border: 0;
}
.b-button.m-white {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #e0e0e0 !important;
}
.b-button.m-ghost {
  background-color: transparent;
  border-width: 1px !important;
  border-style: solid !important;
  box-shadow: none;
}
.b-button.m-simple {
  background-color: transparent;
  color: #292e30 !important;
  box-shadow: none;
}
.b-button.m-white-and-grey {
  background: #ffffff;
  color: #292e30 !important;
}
.b-button:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.7;
}
.b-button:hover.m-white {
  color: #000;
}
.b-button[disabled] {
  background-color: #ececec !important;
  opacity: 1 !important;
}
.b-button[disabled]:hover {
  cursor: not-allowed;
  opacity: 1 !important;
  background-color: #ececec !important;
  color: #000 !important;
}
.b-button.lpb-button-default {
  background: none;
  border: 1px solid #757575;
}
.b-button.lpb-button-default:hover {
  color: unset;
}
.b-button.lpb-button-ghost {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  margin-left: 0;
  margin-right: 0;
}
.b-button.lpb-button-ghost:hover {
  color: #fff;
}
.b-button.lpb-button-main {
  background-color: #bd2e7e;
  color: #ffffff;
}
.b-button.lpb-button-empty-background {
  border: 2px solid #bd2e7e;
  color: #bd2e7e;
  background: #ffffff;
}
.b-button.lpb-button-empty-background-danger {
  border: 2px solid #dc3545;
  color: #dc3545;
  background: #ffffff;
}
.b-button.lpb-button-empty-background-warning {
  border: 2px solid #ffc107;
  color: #ffc107;
  background: #ffffff;
}
.b-button.lpb-button-custom-color {
  background-color: var(--lpb-main-color);
  color: #ffffff;
  transition: background-color 200ms;
}
.b-button.lpb-button-danger {
  background-color: #dc3545;
  color: #ffffff;
}
.b-button.lpb-button-warning {
  background-color: #ffc107;
  color: #ffffff;
}

.b-button-default {
  background-color: #475b63;
}

.b-button-admin {
  background-color: #5faee3;
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  cursor: pointer;
}
.b-button-admin:hover, .b-button-admin:visited {
  color: #fff;
}
.b-button-admin.warning {
  background-color: #ff0000;
}
.b-button-admin.success {
  background-color: #2ecc71;
}
.b-button-admin.ghost {
  border: 1px solid #707070;
  background-color: transparent;
  color: #9197a0;
}
.b-button-admin.ghost:hover, .b-button-admin.ghost:visited {
  color: #9197a0;
}

.b-button-back {
  cursor: pointer;
  display: inline-block;
}
.b-button-back .b-button-back-link {
  color: #616161;
  text-decoration: none;
}

.test-button {
  margin-left: 15px;
}

.bs-button-tooltip {
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  white-space: pre-wrap;
  text-align: center;
  max-width: 360px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.b-custom-scroll-bar::-webkit-scrollbar-track,
.b-custom-scroll-bar .mat-tab-body.mat-tab-body-active::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}
.b-custom-scroll-bar::-webkit-scrollbar,
.b-custom-scroll-bar .mat-tab-body.mat-tab-body-active::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
.b-custom-scroll-bar::-webkit-scrollbar-thumb,
.b-custom-scroll-bar .mat-tab-body.mat-tab-body-active::-webkit-scrollbar-thumb {
  background-color: #757575;
  border: none;
}
.b-custom-scroll-bar.m-ligth::-webkit-scrollbar-thumb {
  background-color: #adadad;
}

.custom-scroll ::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}
.custom-scroll ::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}
.custom-scroll ::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border: none;
}

.custom-progress-bar {
  height: 25px;
  border-radius: 50px;
  background-color: #ffffff;
  border: 1px solid #757575;
  min-width: 100px;
}
.custom-progress-bar .progress-bar {
  background-color: var(--color-primary);
}

.b-custom-mat-tab-group .mat-tab-header {
  padding-bottom: 0 !important;
}
.b-custom-mat-tab-group .mat-tab-body-content {
  height: auto;
}
.b-custom-mat-tab-group .mat-tab-label {
  height: 70px;
  font-size: 17px;
  font-weight: 600;
  color: #757575;
}
.b-custom-mat-tab-group .mat-tab-label:focus {
  background-color: transparent !important;
}
.b-custom-mat-tab-group .mat-tab-label.mat-tab-label-active {
  color: #292e30;
}
.b-custom-mat-tab-group.m-auto-tab-width .mat-tab-label {
  min-width: 0;
}

.b-custom-mat-tab-group-style-2 .mat-tab-label {
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.25rem;
}
.b-custom-mat-tab-group-style-2 .mat-tab-label + .mat-tab-label {
  margin-left: 50px;
}
.b-custom-mat-tab-group-style-2 .mat-tab-label-active {
  opacity: 1;
}
.b-custom-mat-tab-group-style-2 .mat-tab-header {
  border-bottom: 0;
}
.b-custom-mat-tab-group-style-2 .mat-ink-bar {
  background-color: #fc7d74 !important;
}

.upln-ngx-datatable {
  border: 1px solid #eceeef;
  color: rgb(33, 37, 41);
}
.upln-ngx-datatable .datatable-header {
  border-bottom: 2px solid #ccc;
}
.upln-ngx-datatable .datatable-header-cell {
  padding: 12px;
  font-size: 12px;
}
.upln-ngx-datatable .datatable-header-cell + .datatable-header-cell,
.upln-ngx-datatable .datatable-body-cell + .datatable-body-cell {
  border-left: 1px solid rgba(145, 151, 160, 0.25);
}
.upln-ngx-datatable .datatable-header-cell-template-wrap {
  display: flex;
}
.upln-ngx-datatable.ngx-datatable .datatable-body-cell {
  display: flex;
  align-items: center;
}
.upln-ngx-datatable .datatable-body-cell-label {
  padding: 12px 12px;
  font-size: 12px;
}
.upln-ngx-datatable.ngx-datatable .datatable-body .datatable-scroll {
  display: block;
}
.upln-ngx-datatable .datatable-row-wrapper:nth-child(even) {
  background-color: #f2f2f2;
}
.upln-ngx-datatable .empty-row {
  padding: 30px;
  text-align: center;
}
.upln-ngx-datatable .danger-row {
  background-color: #f7d6d1;
  border-bottom: 1px solid #dcc5c3;
  border-top: 1px solid #dcc5c3;
}
.upln-ngx-datatable datatable-header-cell.sortable:before, .upln-ngx-datatable datatable-header-cell.sortable:after {
  font-family: "IcoFont";
  position: absolute;
  top: 6px;
  color: #ccc;
  font-size: 20px;
  content: "\eb53";
  right: 10px;
}
.upln-ngx-datatable datatable-header-cell.sortable:after {
  content: "\eb53";
  right: 10px;
}
.upln-ngx-datatable datatable-header-cell.sortable:before {
  content: "\eb56";
  right: 15px;
}
.upln-ngx-datatable datatable-header-cell.sortable.sort-active.sort-desc:after {
  color: #666;
}
.upln-ngx-datatable datatable-header-cell.sortable.sort-active.sort-asc:before {
  color: #666;
}

.ad-details {
  display: flex;
  font-size: 12px;
}

.ad-details-image {
  flex-shrink: 0;
  width: 80px;
  height: 55px;
  margin-right: 33px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ad-details-headline {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: inherit;
}

.admin-page-heading-title {
  font-size: 24px;
}

.admin-page-heading-highlighted {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 3px solid #fc7d74;
}

.admin-page-heading-info {
  font-size: 16px;
  color: red;
}

.image-upload-preview {
  cursor: pointer;
  position: relative;
}
.image-upload-preview img {
  height: 175px;
}
.image-upload-preview .image-upload-preview-delete-button,
.image-upload-preview .main-container button.image-upload-preview-delete-button {
  position: absolute;
  top: 10px;
  right: 15px;
  display: inline-flex;
  padding: 7px;
  border-radius: 5px;
  opacity: 0.5;
  transition: opacity 300ms;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.image-upload-preview .image-upload-preview-delete-button:hover,
.image-upload-preview .main-container button.image-upload-preview-delete-button:hover {
  opacity: 1;
}
.image-upload-preview .image-upload-preview-delete-button:focus,
.image-upload-preview .main-container button.image-upload-preview-delete-button:focus {
  outline: none;
}

.image-upload-label {
  width: 100%;
  min-height: 135px;
  padding: 20px 25px;
  border: 1px dashed #292e30;
  background-color: #ffffff;
  cursor: pointer;
}
.image-upload-label .image-upload-label-add-image-text {
  margin-left: 15px;
  font-size: 12px;
  color: #bd2e7e;
}
.image-upload-label .image-upload-label-tips {
  font-size: 9px;
}
.image-upload-label .image-upload-label-title {
  margin-bottom: 10px;
}

.ai-square {
  background-color: #02b9b5;
  width: 300px;
  height: 300px;
  transform: rotate(58deg);
  position: absolute;
  border-radius: 40px;
  box-shadow: 0 0 20px #02b9b5;
}

.bs-mat-accordion.mat-accordion {
  display: block;
  background-color: #1b1f21;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel,
.bs-mat-accordion.mat-accordion .mat-expansion-panel-spacing {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel:first-child,
.bs-mat-accordion.mat-accordion .mat-expansion-panel-spacing:first-child {
  margin-top: 0;
  border-radius: 0;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel:last-child,
.bs-mat-accordion.mat-accordion .mat-expansion-panel-spacing:last-child {
  margin-bottom: 0;
  border-radius: 0;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel {
  box-shadow: none;
  background-color: #292f31;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel.disabled .mat-expansion-panel-header-title {
  color: #9a9a9a;
  cursor: default;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-header {
  padding-left: 0;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-header .mat-icon {
  position: absolute;
  margin-left: -20px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-header.mat-expanded .mat-icon {
  transform: rotate(90deg);
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-header-title {
  position: relative;
  align-items: center;
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-content {
  color: #fff;
}
.bs-mat-accordion.mat-accordion .mat-expansion-panel-body {
  padding-left: 30px;
}
.bs-mat-accordion.mat-accordion.light-theme {
  background-color: #ffffff;
}
.bs-mat-accordion.mat-accordion.light-theme .mat-expansion-panel-content {
  color: #292e30;
}
.bs-mat-accordion.mat-accordion.light-theme > .mat-expansion-panel {
  background-color: #ffffff;
}
.bs-mat-accordion.mat-accordion.light-theme > .mat-expansion-panel > .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #292e30;
}
.bs-mat-accordion.mat-accordion .mat-expansion-indicator {
  display: none;
}

.bs-mat-tab-group.mat-tab-group {
  background-color: #fff;
}
.bs-mat-tab-group.mat-tab-group .mat-tab-label {
  width: 100%;
}
.bs-mat-tab-group.mat-tab-group .mat-ink-bar {
  background-color: #bd2e7e;
  height: 3px;
}
.bs-mat-tab-group.mat-tab-group .mat-tab-header {
  border-bottom: none;
  background-color: #292f31;
}
.bs-mat-tab-group.mat-tab-group .mat-tab-label {
  color: #fff;
  opacity: 1;
  min-width: 0;
}
.bs-mat-tab-group.mat-tab-group .mat-tab-label.mat-tab-disabled {
  opacity: 0.6;
}
.bs-mat-tab-group.mat-tab-group .mat-tab-body {
  background-color: #292f31;
  color: #fff;
}

.bs-control-box {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  width: 100%;
}
.bs-control-box .bs-control-box-label {
  display: block;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 24px;
}
.bs-control-box .bs-control-box-tip {
  font-size: 12px;
  opacity: 0.5;
}
.bs-control-box.bs-image-upload-box .image-upload-preview {
  margin-bottom: 0;
  margin-right: 0;
}
.bs-control-box.bs-image-upload-box .image-upload-preview img {
  width: 100%;
  height: auto !important;
}

.bs-form-field {
  display: block;
  width: 100%;
  min-width: 0;
  border-radius: 5px;
  overflow: hidden;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0;
}
.bs-form-field .mat-form-field-flex {
  background-color: #363a3c;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #707070;
  top: 0;
}
.bs-form-field .mat-form-field-infix {
  padding: 2px;
  border-top: 0;
  font-size: 14px;
  width: 100%;
  line-height: 28px;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  position: static;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-prefix,
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  font-size: 12px;
  top: 0;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  color: #a9a9a9;
  padding-left: 5px;
}
.bs-form-field.mat-form-field-appearance-outline .mat-form-field-prefix {
  color: #a9a9a9;
  padding-right: 5px;
  padding-left: 5px;
}
.bs-form-field .mat-error {
  margin-top: 7px;
}
.bs-form-field .mat-chip-input {
  width: auto;
  margin: auto;
  flex: auto;
}
.bs-form-field .mat-input-element {
  font-size: 12px;
  border-radius: 5px;
}
.bs-form-field .mat-input-element::placeholder {
  color: #888888;
}
.bs-form-field .mat-input-element:disabled {
  color: rgba(255, 255, 255, 0.38);
}
.bs-form-field .mat-input-element:-webkit-autofill, .bs-form-field .mat-input-element:-webkit-autofill:hover, .bs-form-field .mat-input-element:-webkit-autofill:focus, .bs-form-field .mat-input-element:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #363a3c inset;
  -webkit-text-fill-color: #fff;
}
.bs-form-field .mat-input-element[type=number]::-webkit-inner-spin-button, .bs-form-field .mat-input-element[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bs-form-field .mat-input-element option {
  background-color: #363a3c;
}
.bs-form-field .mat-checkbox,
.bs-form-field label {
  font-size: 12px;
}
.bs-form-field textarea.mat-input-element {
  resize: none;
  line-height: 1.6;
  color: #fff;
}
.bs-form-field textarea.mat-input-element::-webkit-scrollbar-track {
  background-color: #363a3c;
}
.bs-form-field textarea.mat-input-element::-webkit-scrollbar-thumb {
  background-color: #707070;
}
.bs-form-field.textarea-resizable textarea.mat-input-element {
  resize: vertical;
}
.bs-form-field .mat-form-field-infix {
  height: auto;
}
.bs-form-field.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-infix::after,
.bs-form-field .mat-select .mat-form-field-infix::after {
  margin-top: -2px;
  color: #fff;
  pointer-events: none;
}
.bs-form-field.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-flex,
.bs-form-field .mat-select .mat-form-field-flex {
  position: relative;
}
.bs-form-field.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-flex:after,
.bs-form-field .mat-select .mat-form-field-flex:after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: #707070;
  position: absolute;
  right: 30px;
  top: 0;
}
.bs-form-field .mat-select .mat-select-value {
  color: white;
}
.bs-form-field.mat-checkbox {
  display: inline-block;
  width: auto;
  max-width: 100%;
}
.bs-form-field.mat-checkbox.mat-checkbox-checked .mat-checkbox-inner-container .mat-checkbox-background {
  background-color: #bd2e7e !important;
}
.bs-form-field.mat-checkbox.mat-checkbox-indeterminate .mat-checkbox-inner-container .mat-checkbox-background {
  background-color: #bd2e7e !important;
}
.bs-form-field.mat-checkbox.mat-checkbox-disabled {
  opacity: 0.4;
}
.bs-form-field.mat-checkbox.mat-checkbox-disabled .mat-checkbox-label {
  color: inherit !important;
}
.bs-form-field.mat-checkbox .mat-checkbox-layout {
  max-width: 100%;
}
.bs-form-field.mat-checkbox .mat-checkbox-label {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-form-field.mat-selection-list .mat-list-item {
  height: 36px !important;
}
.bs-form-field.mat-selection-list .mat-list-item * {
  color: white;
  font-size: 14px;
}
.bs-form-field.mat-selection-list .mat-list-item .mat-pseudo-checkbox {
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1), background-color 90ms cubic-bezier(0, 0, 0.2, 0.1), opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
}
.bs-form-field.mat-selection-list .mat-list-item .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #bd2e7e !important;
}
.bs-form-field.mat-selection-list .mat-list-item .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
  opacity: 0.4;
}
.bs-form-field.mat-selection-list .mat-list-item .mat-list-item-content {
  padding-left: 0 !important;
}
.bs-form-field.mat-tree {
  background: none;
}
.bs-form-field.mat-tree * {
  color: white !important;
  font-size: 14px !important;
}
.bs-form-field.mat-tree .mat-tree-node {
  height: 36px;
  min-height: 36px;
}
.bs-form-field.mat-tree .mat-tree-node .mat-icon {
  font-size: 24px !important;
}
.bs-form-field.mat-tree .mat-tree-node .mat-checkbox-layout {
  margin-bottom: 0;
}
.bs-form-field .mat-datepicker-toggle button {
  position: absolute;
  top: -20px;
  right: 0;
  background-color: transparent;
}
.bs-form-field .mat-datepicker-toggle button .mat-button-ripple {
  display: none;
}
.bs-form-field .mat-datepicker-toggle button .mat-button-focus-overlay {
  display: none;
}
.bs-form-field .mat-select-value {
  font-size: 12px;
}
.bs-form-field .mat-select-arrow {
  color: #ffffff;
}
.bs-form-field.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateX(7px);
}
.bs-form-field .mat-select-value::after {
  content: "";
  width: 1px;
  display: block;
  height: 30px;
  position: absolute;
  top: -8px;
  background-color: #707070;
  right: 15px;
}
.bs-form-field.search .mat-form-field-flex::after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: #707070;
  position: absolute;
  right: 31px;
  top: 0;
}
.bs-form-field.search .mat-form-field-infix::after {
  content: "\e8b6";
  font-family: "Material Icons";
  position: absolute;
  top: 2px;
  right: -8px;
  font-size: 21px;
}
.bs-form-field.light .mat-form-field-flex {
  background-color: white;
}
.bs-form-field.light.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-infix::after {
  color: #363a3c;
}
.bs-form-field.light.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-flex:after,
.bs-form-field.light .mat-select .mat-form-field-flex:after {
  background-color: #cecece;
}
.bs-form-field.light .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 1px 0 0 1px;
}
.bs-form-field.light .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 1px 1px 0;
}
.bs-form-field.light .mat-form-field-outline-start {
  border-color: #cecece;
  border-radius: 2px 0 0 2px;
}
.bs-form-field.light .mat-form-field-outline-end {
  border-color: #cecece;
  border-radius: 0 2px 2px 0;
}
.bs-form-field.light .mat-input-element:-webkit-autofill, .bs-form-field.light .mat-input-element:-webkit-autofill:hover, .bs-form-field.light .mat-input-element:-webkit-autofill:focus, .bs-form-field.light .mat-input-element:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #363a3c;
}
.bs-form-field.light .mat-input-element option {
  background-color: transparent;
}
.bs-form-field.light .mat-datepicker-toggle button {
  color: #363a3c;
}
.bs-form-field.light textarea.mat-input-element {
  color: #363a3c;
}
.bs-form-field.light .mat-select-arrow {
  color: #363a3c;
}
.bs-form-field.light .mat-select .mat-select-value {
  color: inherit;
}
.bs-form-field.select-chips .mat-form-field-infix {
  height: auto;
}
.bs-form-field.select-chips .mat-select-trigger {
  height: 100%;
}
.bs-form-field.select-chips .mat-select-value {
  line-height: 24px;
  max-height: 84px;
  overflow: auto;
}
.bs-form-field.select-chips .mat-select-value .mat-select-placeholder {
  color: white;
}
.bs-form-field.select-chips .mat-select-value::after {
  height: calc(100% + 6px);
  min-height: 31px;
  top: -4px;
}
.bs-form-field.select-chips .mat-chip {
  font-size: 12px;
  padding: 5px 3px 3px 9px;
  min-height: 22px;
  background-color: rgba(255, 255, 255, 0.16);
  color: white;
}
.bs-form-field.select-chips .mat-chip-remove {
  margin-bottom: 2px;
  opacity: 0.7;
  color: white;
}
.bs-form-field.select-chips .mat-chip-list-wrapper {
  margin-bottom: -1px;
}

.bs-sidebar-offset {
  width: 340px;
}
.bs-sidebar-offset.bs-sidebar-offset-wide {
  width: 380px;
}

.bs-sidebar-container {
  position: fixed;
  width: 340px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #292f31;
  color: white;
  overflow-y: auto;
}
.bs-sidebar-container > * {
  flex-shrink: 0;
}
.bs-sidebar-container.bs-sidebar-container-wide {
  width: 380px;
}
.bs-sidebar-container .bs-sidebar-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0 20px;
  background-color: #363a3c;
  color: #ffffff;
}
.bs-sidebar-container .bs-sidebar-header .bs-sidebar-header-button {
  display: flex;
  width: 50px;
  height: 40px;
  background-color: unset;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.bs-sidebar-container .bs-sidebar-header .bs-sidebar-header-button.cyan.active {
  border-color: #00b9b5;
}
.bs-sidebar-container .bs-sidebar-header .bs-sidebar-header-button.purple.active {
  border-color: #bd2e7e;
}
.bs-sidebar-container .bs-sidebar-header.light-theme {
  background-color: #ffffff;
}
.bs-sidebar-container .bs-sidebar-divider {
  width: 100%;
  height: 10px;
  background-color: #1b1f21;
}
.bs-sidebar-container .bs-sidebar-divider.gray-thin {
  border: 1px solid #707070;
  margin: 16px 0;
  width: auto;
  height: 0;
}
.bs-sidebar-container .bs-sidebar-inline-elements {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-column-gap: 11px;
}
.bs-sidebar-container .bs-sidebar-small-input {
  width: 65px;
  margin-left: 11px;
}
.bs-sidebar-container .bs-sidebar-content {
  padding: 20px;
}
.bs-sidebar-container .bs-sidebar-content-title {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  margin-bottom: 10px;
}
.bs-sidebar-container .bs-sidebar-footer {
  margin-top: auto;
  padding: 20px;
}
.bs-sidebar-container .bs-sidebar-hint {
  font-size: 12px;
  color: #bdbdbd;
  line-height: 1.3;
}
.bs-sidebar-container .heading-error {
  font-size: 12px;
  color: #f44336;
  margin-bottom: 7px;
}
.bs-sidebar-container.light-theme {
  background-color: #ffffff;
}

.modal-black {
  position: relative;
  background: #292e30;
  color: white;
  padding: 40px;
  height: 100%;
  border-radius: 4px;
}
.modal-black .mat-dialog-actions {
  margin-bottom: 0;
  padding-bottom: 0;
}
.modal-black .icon-content {
  height: 150px;
  width: 150px;
  margin: auto;
  background-color: white;
  border-radius: 100%;
}
.modal-black .icon-content .icon-large {
  width: 72px;
  height: 72px;
}
.modal-black .text-content-center {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}
.modal-black .close-button {
  position: absolute;
  right: 10px;
  top: 8px;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.modal-black .close-button .mat-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.modal-black .dialog-header-text {
  font-weight: 600;
}
.modal-black .mat-dialog-actions {
  justify-content: space-between;
}

.modal-white {
  position: relative;
  padding: 40px;
  color: #292e30;
  height: 100%;
  border-radius: 4px;
}
.modal-white .mat-dialog-actions {
  margin-bottom: 0;
  padding-bottom: 0;
}
.modal-white .icon-content {
  height: 150px;
  width: 150px;
  margin: auto;
  background-color: white;
  border-radius: 100%;
}
.modal-white .icon-content .icon-large {
  width: 72px;
  height: 72px;
}
.modal-white .text-content-center {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}
.modal-white .close-button {
  position: absolute;
  right: 10px;
  top: 8px;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.modal-white .close-button .mat-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.modal-white .dialog-header-text {
  font-weight: 600;
}
.modal-white .mat-dialog-actions {
  justify-content: space-between;
}

.bs-button.bs-button {
  min-width: 140px;
  margin-bottom: 0;
  padding: 10px;
  border: 1px solid #bd2e7e;
  border-radius: 2px;
  background-color: #bd2e7e;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  transition: opacity 200ms;
  cursor: pointer;
  font-weight: 600;
}
.bs-button.bs-button.thin {
  padding: 5px 10px;
}
.bs-button.bs-button.bs-button-responsive {
  min-width: 0;
  width: 100%;
}
.bs-button.bs-button.bs-button-black {
  border-color: #707070;
  background-color: #1b1f21;
}
.bs-button.bs-button.bs-button-white {
  border-color: white;
  background-color: white;
  color: #363636;
}
.bs-button.bs-button.bs-button-gray {
  border-color: #707070;
  background-color: #363a3c;
}
.bs-button.bs-button.bs-button-brown {
  border-color: #cab299;
  background-color: #cab299;
}
.bs-button.bs-button.bs-button-teal {
  border-color: #00b9b5;
  background-color: #00b9b5;
}
.bs-button.bs-button.bs-button-magenta {
  border-color: #ce1080;
  background-color: #ce1080;
}
.bs-button.bs-button.bs-button-ghost {
  border-color: #707070;
  background-color: transparent;
  color: #707070;
}
.bs-button.bs-button.bs-button-ghost-white {
  border-color: #707070;
  background-color: transparent;
  color: #fff;
}
.bs-button.bs-button.bs-button-simple {
  background-color: transparent;
  border: 0;
}
.bs-button.bs-button.bs-button-border-radius-large {
  border-radius: 22px;
}
.bs-button.bs-button.bs-button-flex {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}
.bs-button.bs-button.bs-button-flex .bs-button-inner-icon {
  position: absolute;
  right: 0;
}
.bs-button.bs-button.bs-button-icon {
  min-width: 60px;
}
.bs-button.bs-button[disabled] {
  opacity: 0.5;
  cursor: unset;
  color: #fff;
}
.bs-button.bs-button:not([disabled]):hover {
  opacity: 0.8;
}
.bs-button.bs-button:not([disabled]):focus {
  outline: none;
}
.bs-button.bs-button.no-border {
  border-color: transparent;
}
.bs-button.bs-button.text-button {
  font-size: 12px;
  color: #bd2e7e;
  font-weight: 600;
  background-color: transparent;
  padding: 0;
  border: none;
  min-width: 0;
}
.bs-button.bs-button.text-button.black {
  color: black;
}

.bs-back-link-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
}
.bs-back-link-button .mat-icon {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}

.bs-slider.bs-slider.bs-slider {
  width: 100%;
  padding: 0;
  height: 31px;
}
.bs-slider.bs-slider.bs-slider .mat-slider-wrapper {
  top: 15px;
  left: 0;
  right: 0;
}
.bs-slider.bs-slider.bs-slider .mat-slider-track-background {
  background-color: #1b1f21;
}
.bs-slider.bs-slider.bs-slider .mat-slider-track-fill {
  background-color: #1b1f21;
}
.bs-slider.bs-slider.bs-slider .mat-slider-thumb {
  background-color: #fff;
  border: 0;
}
.bs-slider.bs-slider.bs-slider.light-theme .mat-slider-track-background {
  background-color: #00b9b5;
}
.bs-slider.bs-slider.bs-slider.light-theme .mat-slider-track-fill {
  background-color: #00b9b5;
}
.bs-slider.bs-slider.bs-slider.light-theme .mat-slider-thumb {
  background-color: #00b9b5;
  border: 0;
}

.bs-color-picker-wrapper {
  display: flex;
  align-items: center;
}
.bs-color-picker-wrapper .bs-color-picker {
  width: 30px;
  height: 30px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
}
.bs-color-picker-wrapper .bs-color-picker-value {
  margin-left: 7px;
  font-size: 12px;
  width: 25px;
}
.bs-color-picker-wrapper .color-picker {
  transition: none;
}

.bs-header-container {
  height: 58px;
  background-color: #1b1f21;
  font-size: 16px;
  color: white;
}
.bs-header-container .bs-button {
  margin: 0 10px;
}

.bs-header-name {
  width: 185px;
  padding-left: 21px;
  padding-right: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bs-sections-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}

.bs-sections-block-title {
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  padding: 16px;
}

.bs-section-box {
  height: 75px;
  display: inline-block;
  max-width: 160px;
}
.bs-section-box .bs-section-item {
  height: 100%;
  border-radius: 5px;
  background-color: #363a3c;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bs-section-box .bs-section-item.disabled {
  background-color: #2e3335;
  cursor: default;
}
.bs-section-box .bs-section-item upln-tooltip {
  position: absolute;
  right: 7px;
  top: 0;
}
.bs-section-box .bs-section-item .mat-icon {
  margin: 8px 0;
}
.bs-section-box .bs-section-types-item-title {
  font-size: 11px;
  color: #ffffff;
}
.bs-section-box .bs-section-types-disabled-item-title {
  font-size: 11px;
  color: #7c8081;
}

.popover-black .mat-dialog-container {
  overflow: visible;
}
.popover-black .popover-angle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #292e30;
  top: -8px;
  transform: rotate(45deg);
  border-radius: 4px;
}

.drop-section-placeholder {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px dashed #027ffd;
  text-align: center;
  color: #027ffd;
  z-index: 2;
}

.sections-drop-zone {
  position: relative;
}
.sections-drop-zone:not(.no-overlay).cdk-drop-list-receiving:after, .sections-drop-zone.footer-receiving:after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border: 4px dashed rgb(99, 177, 255);
  pointer-events: none;
  content: attr(data-drop-section-text);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 26px;
}
.sections-drop-zone.cdk-drop-list-receiving, .sections-drop-zone.cdk-drop-list-dragging {
  cursor: move;
}
.sections-drop-zone.cdk-drop-list-dragging .sections-drop-zone-item {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.sections-drop-zone .footer-drop-placeholder {
  opacity: 0;
  height: 0;
  padding: 0;
  border: 0;
}
.sections-drop-zone.footer-receiving .sections-drop-zone-item {
  transform: none !important;
}

.cdk-drop-list-dragging .editable-section:hover .editable-section-overlay:not(.section-edit) {
  opacity: 0;
}

.drag-and-drop-list .cdk-drag-placeholder {
  opacity: 0;
}
.drag-and-drop-list .cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.drag-and-drop-list.cdk-drop-list-dragging .drag-and-drop-list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-and-drop-list-item {
  margin-bottom: 10px;
}
.drag-and-drop-list-item .drag-and-drop-list-item-action {
  background-color: transparent;
  padding: 5px;
  color: #fff;
  border: 0;
}
.drag-and-drop-list-item .drag-and-drop-list-item-action .mat-icon {
  display: block;
}
.drag-and-drop-list-item .drag-and-drop-list-item-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
}

.drag-and-drop-list-panel {
  background-color: #363a3c;
  padding: 8px 0;
  border-radius: 5px;
  display: flex;
}

.bs-radio .mat-radio-button .mat-radio-inner-circle,
.bs-radio .mat-radio-button .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.bs-radio .mat-radio-button.mat-radio-checked .mat-radio-persistent-ripple,
.bs-radio .mat-radio-button:active .mat-radio-persistent-ripple {
  background-color: #bd2e7e;
}
.bs-radio .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd2e7e;
}
.bs-radio .mat-radio-outer-circle {
  background-color: #363a3c;
  border: 1px solid #707070;
  width: 18px;
  height: 18px;
}
.bs-radio .mat-radio-inner-circle {
  width: 18px;
  height: 18px;
}
.bs-radio .mat-radio-button .mat-radio-ripple {
  display: none;
}
.bs-radio .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.7);
}
.bs-radio.minimal .mat-radio-container {
  display: none;
}
.bs-radio.minimal .mat-radio-checked .mat-radio-label-content {
  color: #bd2e7e;
}

.bs-autocomplete .mat-option {
  background-color: #363a3c;
  color: white;
  font-size: 12px;
}
.bs-autocomplete .mat-option.mat-option-disabled {
  color: #8c8c8c;
}
.bs-autocomplete .mat-option:not(.mat-option-disabled):hover {
  background-color: #3f4446;
}

.bs-dropdown-menu.mat-menu-panel {
  min-height: 50px;
}
.bs-dropdown-menu .mat-menu-item {
  line-height: 33px;
  height: 33px;
}
.bs-dropdown-menu .mat-menu-item:hover {
  text-decoration: none;
  color: inherit;
}
.bs-dropdown-menu .mat-menu-item[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bs-dropdown-menu .mat-icon {
  height: 17px;
  width: 17px;
  font-size: 17px;
  color: #333;
}
.bs-dropdown-menu button[disabled] .mat-icon {
  opacity: 0.4;
}

.panel-content-wrapper {
  color: #292e30;
}
.panel-content-wrapper .panel-header {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 33px;
  line-height: 51px;
}
.panel-content-wrapper .panel-content {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.widget-content-wrapper {
  color: #707070;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 13px;
}
.widget-content-wrapper .widget-header {
  font-size: 17px;
  color: #292e30;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.widget-content-wrapper .widget-edit-button {
  color: #bd2e7e;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: initial;
}

.bs-counter {
  display: flex;
  flex-direction: column;
  color: #292e30;
  font-weight: 600;
}
.bs-counter .counter-value {
  font-size: 36px;
  line-height: 42px;
}
.bs-counter .counter-value.red {
  color: #e25874;
}
.bs-counter .counter-value.green {
  color: #51a351;
}
.bs-counter .counter-name {
  font-size: 13px;
}
.bs-counter.space-right {
  padding-right: 70px;
}
.bs-counter.space-left {
  padding-left: 70px;
}
.bs-counter.border-right {
  border-right: 1px solid #707070;
}

.bs-table {
  table-layout: fixed;
  width: 100%;
  font-size: 14px;
  color: #292e30;
}
.bs-table th,
.bs-table td {
  padding: 12px 0;
  border-bottom: 1px solid rgba(208, 208, 208, 0.8666666667);
}
.bs-table th.overflow,
.bs-table td.overflow {
  overflow: visible;
}
.bs-table th {
  font-weight: 600;
}
.bs-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.bs-table .mat-checkbox-layout {
  margin: 0;
}
.bs-table.wrap-text td {
  white-space: pre-wrap;
}
.bs-table.auto-layout {
  table-layout: auto;
}
.bs-table.cursor-pointer-rows td {
  cursor: pointer;
}
.bs-table .menu-container {
  display: flex;
  align-items: center;
  flex-grow: 0;
}

.select-file-item {
  max-width: 145px;
  height: 80px;
  border: 1px solid #707070;
  border-radius: 5px;
  background-color: #363a3c;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.select-file-item img {
  width: 37px;
}
.select-file-item mat-icon {
  height: 37px;
}
.select-file-item.upload-image {
  border-style: dashed;
}

.bs-slide-toggle.bs-slide-toggle.mat-slide-toggle .mat-slide-toggle-content {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.bs-slide-toggle.bs-slide-toggle.purple.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #bd2e7e;
}
.bs-slide-toggle.bs-slide-toggle.purple.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #bd2e7e;
}
.bs-slide-toggle.bs-slide-toggle.cyan.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #00b9b5;
}
.bs-slide-toggle.bs-slide-toggle.cyan.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00b9b5;
}
.bs-slide-toggle.bs-slide-toggle.magenta.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #bd2e7e;
}
.bs-slide-toggle.bs-slide-toggle.magenta.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #bd2e7e;
}
.bs-slide-toggle.bs-slide-toggle.thumb-white.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar .mat-slide-toggle-thumb {
  background-color: white;
}
.bs-slide-toggle.bs-slide-toggle.big.mat-slide-toggle .mat-slide-toggle-thumb-container {
  width: 22px;
  height: 22px;
  top: 2px;
}
.bs-slide-toggle.bs-slide-toggle.big.mat-slide-toggle .mat-slide-toggle-bar {
  height: 24px;
  border-radius: 12px;
  width: 46px;
}
.bs-slide-toggle.bs-slide-toggle.big.mat-slide-toggle .mat-slide-toggle-bar .mat-slide-toggle-thumb {
  background-color: white;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 1px 1px #909090;
}
.bs-slide-toggle.bs-slide-toggle.big.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(24px, 0, 0);
}
.bs-slide-toggle.bs-slide-toggle .mat-ripple {
  display: none;
}

.bs-chip.mat-chip {
  border-radius: 5px;
  color: white;
}
.bs-chip.mat-chip .mat-icon.mat-chip-remove {
  color: white;
  opacity: 1;
}
.bs-chip.dark {
  background-color: #363a3c;
}

.mat-tooltip.bs-tooltip-message {
  color: #1b1f21;
  background-color: white;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 12px;
  overflow: visible;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.mat-tooltip.bs-tooltip-message::before {
  width: 14px;
  height: 14px;
  background-color: white;
  position: absolute;
  content: "";
  transform: rotate(45deg);
  top: calc(50% - 8px);
  border-radius: 2px;
}
.mat-tooltip.bs-tooltip-message.right::before {
  left: -4px;
  box-shadow: -2px 5px 6px -3px rgba(0, 0, 0, 0.16);
}
.mat-tooltip.bs-tooltip-message.left::before {
  right: -4px;
  box-shadow: 2px -5px 6px -3px rgba(0, 0, 0, 0.16);
}

.bs-tabs-2 .mat-tab-label {
  opacity: 1;
  border-right: 1px solid #707070;
  font-size: 12px;
  height: 32px;
  font-weight: 600;
}
.bs-tabs-2 .mat-tab-label:focus {
  background-color: transparent;
}
.bs-tabs-2 .mat-tab-label:first-child {
  border-left: 1px solid #707070;
}
.bs-tabs-2.inverse .mat-tab-label {
  color: white;
  border-right-width: 2px;
  font-weight: 400;
}
.bs-tabs-2.inverse .mat-tab-label:first-child {
  border-left-width: 2px;
}
.bs-tabs-2 .mat-tab-label.mat-tab-label-active {
  font-weight: 600;
  color: #bd2e7e;
}
.bs-tabs-2 .mat-tab-header {
  border: 0;
  margin: 0 5px 20px;
}
.bs-tabs-2 .mat-ink-bar {
  display: none;
}

.bs-scroll ::-webkit-scrollbar-track {
  background-color: #292e30;
}
.bs-scroll ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8e8e8e;
}

.bs-simple-list .list-item {
  display: flex;
  align-items: center;
  background-color: #363a3c;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 7px;
}
.bs-simple-list .edit-button {
  min-width: 90px;
  margin-right: 8px;
}
.bs-simple-list .item-name {
  line-height: 30px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-simple-list .delete-icon {
  cursor: pointer;
}

.bs-fake-tabs {
  display: flex;
}
.bs-fake-tabs .tab {
  cursor: pointer;
  border-right: 1px solid #707070;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  font-weight: 600;
  padding: 0 24px;
  min-width: 160px;
  text-align: center;
}
.bs-fake-tabs .tab:first-child {
  border-left: 1px solid #707070;
}
.bs-fake-tabs .tab.active {
  color: #bd2e7e;
}

.bs-validation-error {
  font-size: 12px;
  margin-top: 3px;
  color: #f44336;
}

.disabled-container {
  filter: blur(3px) brightness(0.6);
  pointer-events: none;
  user-select: none;
}

.fullscreen-dialog {
  max-width: 100% !important;
}

.f-0 {
  font-size: 0px !important;
}
.f-1 {
  font-size: 1px !important;
}
.f-2 {
  font-size: 2px !important;
}
.f-3 {
  font-size: 3px !important;
}
.f-4 {
  font-size: 4px !important;
}
.f-5 {
  font-size: 5px !important;
}
.f-6 {
  font-size: 6px !important;
}
.f-7 {
  font-size: 7px !important;
}
.f-8 {
  font-size: 8px !important;
}
.f-9 {
  font-size: 9px !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-17 {
  font-size: 17px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-19 {
  font-size: 19px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-21 {
  font-size: 21px !important;
}
.f-22 {
  font-size: 22px !important;
}
.f-23 {
  font-size: 23px !important;
}
.f-24 {
  font-size: 24px !important;
}
.f-25 {
  font-size: 25px !important;
}
.f-26 {
  font-size: 26px !important;
}
.f-27 {
  font-size: 27px !important;
}
.f-28 {
  font-size: 28px !important;
}
.f-29 {
  font-size: 29px !important;
}
.f-30 {
  font-size: 30px !important;
}
.f-31 {
  font-size: 31px !important;
}
.f-32 {
  font-size: 32px !important;
}
.f-33 {
  font-size: 33px !important;
}
.f-34 {
  font-size: 34px !important;
}
.f-35 {
  font-size: 35px !important;
}
.f-36 {
  font-size: 36px !important;
}
.f-37 {
  font-size: 37px !important;
}
.f-38 {
  font-size: 38px !important;
}
.f-39 {
  font-size: 39px !important;
}
.f-40 {
  font-size: 40px !important;
}
.f-42 {
  font-size: 42px !important;
}
.f-44 {
  font-size: 44px !important;
}
.f-45 {
  font-size: 45px !important;
}
.f-46 {
  font-size: 46px !important;
}
.f-48 {
  font-size: 48px !important;
}
.f-50 {
  font-size: 50px !important;
}
.f-52 {
  font-size: 52px !important;
}
.f-54 {
  font-size: 54px !important;
}
.f-55 {
  font-size: 55px !important;
}
.f-56 {
  font-size: 56px !important;
}
.f-58 {
  font-size: 58px !important;
}
.f-60 {
  font-size: 60px !important;
}
.f-62 {
  font-size: 62px !important;
}
.f-64 {
  font-size: 64px !important;
}
.f-65 {
  font-size: 65px !important;
}
.f-66 {
  font-size: 66px !important;
}
.f-68 {
  font-size: 68px !important;
}
.f-700 {
  font-size: 700px !important;
}
.f-72 {
  font-size: 72px !important;
}
.f-74 {
  font-size: 74px !important;
}
.f-75 {
  font-size: 75px !important;
}
.f-76 {
  font-size: 76px !important;
}
.f-78 {
  font-size: 78px !important;
}
.f-80 {
  font-size: 80px !important;
}

.c-main {
  color: #bd2e7e !important;
}

.b-main {
  background-color: #bd2e7e !important;
}

.hover-c-main:hover {
  color: #bd2e7e !important;
}

.c-cyan {
  color: #00b9b5 !important;
}

.b-cyan {
  background-color: #00b9b5 !important;
}

.hover-c-cyan:hover {
  color: #00b9b5 !important;
}

.c-teal {
  color: #00b9b5 !important;
}

.b-teal {
  background-color: #00b9b5 !important;
}

.hover-c-teal:hover {
  color: #00b9b5 !important;
}

.c-pompadour {
  color: #6c264d !important;
}

.b-pompadour {
  background-color: #6c264d !important;
}

.hover-c-pompadour:hover {
  color: #6c264d !important;
}

.c-light-gray {
  color: #f2f6f6 !important;
}

.b-light-gray {
  background-color: #f2f6f6 !important;
}

.hover-c-light-gray:hover {
  color: #f2f6f6 !important;
}

.c-dim-gray {
  color: #707070 !important;
}

.b-dim-gray {
  background-color: #707070 !important;
}

.hover-c-dim-gray:hover {
  color: #707070 !important;
}

.c-dark-gray {
  color: #535353 !important;
}

.b-dark-gray {
  background-color: #535353 !important;
}

.hover-c-dark-gray:hover {
  color: #535353 !important;
}

.c-brown {
  color: #cab299 !important;
}

.b-brown {
  background-color: #cab299 !important;
}

.hover-c-brown:hover {
  color: #cab299 !important;
}

.c-montana-gray {
  color: #363a3c !important;
}

.b-montana-gray {
  background-color: #363a3c !important;
}

.hover-c-montana-gray:hover {
  color: #363a3c !important;
}

.c-suva-grey {
  color: #8e8e8e !important;
}

.b-suva-grey {
  background-color: #8e8e8e !important;
}

.hover-c-suva-grey:hover {
  color: #8e8e8e !important;
}

.c-dark {
  color: #292f31 !important;
}

.b-dark {
  background-color: #292f31 !important;
}

.hover-c-dark:hover {
  color: #292f31 !important;
}

.c-black {
  color: #1b1f21 !important;
}

.b-black {
  background-color: #1b1f21 !important;
}

.hover-c-black:hover {
  color: #1b1f21 !important;
}

.c-cod-grey {
  color: #292e30 !important;
}

.b-cod-grey {
  background-color: #292e30 !important;
}

.hover-c-cod-grey:hover {
  color: #292e30 !important;
}

.c-white {
  color: #ffffff !important;
}

.b-white {
  background-color: #ffffff !important;
}

.hover-c-white:hover {
  color: #ffffff !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-bold {
  font-weight: bold;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}

.m-r-0 {
  margin-right: 0px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-0 {
  margin: 0px;
}
.m-r-1 {
  margin-right: 1px !important;
}
.m-l-1 {
  margin-left: 1px !important;
}
.m-t-1 {
  margin-top: 1px !important;
}
.m-b-1 {
  margin-bottom: 1px !important;
}
.m-1 {
  margin: 1px;
}
.m-r-2 {
  margin-right: 2px !important;
}
.m-l-2 {
  margin-left: 2px !important;
}
.m-t-2 {
  margin-top: 2px !important;
}
.m-b-2 {
  margin-bottom: 2px !important;
}
.m-2 {
  margin: 2px;
}
.m-r-3 {
  margin-right: 3px !important;
}
.m-l-3 {
  margin-left: 3px !important;
}
.m-t-3 {
  margin-top: 3px !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-3 {
  margin: 3px;
}
.m-r-4 {
  margin-right: 4px !important;
}
.m-l-4 {
  margin-left: 4px !important;
}
.m-t-4 {
  margin-top: 4px !important;
}
.m-b-4 {
  margin-bottom: 4px !important;
}
.m-4 {
  margin: 4px;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-5 {
  margin: 5px;
}
.m-r-6 {
  margin-right: 6px !important;
}
.m-l-6 {
  margin-left: 6px !important;
}
.m-t-6 {
  margin-top: 6px !important;
}
.m-b-6 {
  margin-bottom: 6px !important;
}
.m-6 {
  margin: 6px;
}
.m-r-7 {
  margin-right: 7px !important;
}
.m-l-7 {
  margin-left: 7px !important;
}
.m-t-7 {
  margin-top: 7px !important;
}
.m-b-7 {
  margin-bottom: 7px !important;
}
.m-7 {
  margin: 7px;
}
.m-r-8 {
  margin-right: 8px !important;
}
.m-l-8 {
  margin-left: 8px !important;
}
.m-t-8 {
  margin-top: 8px !important;
}
.m-b-8 {
  margin-bottom: 8px !important;
}
.m-8 {
  margin: 8px;
}
.m-r-9 {
  margin-right: 9px !important;
}
.m-l-9 {
  margin-left: 9px !important;
}
.m-t-9 {
  margin-top: 9px !important;
}
.m-b-9 {
  margin-bottom: 9px !important;
}
.m-9 {
  margin: 9px;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-10 {
  margin: 10px;
}
.m-r-11 {
  margin-right: 11px !important;
}
.m-l-11 {
  margin-left: 11px !important;
}
.m-t-11 {
  margin-top: 11px !important;
}
.m-b-11 {
  margin-bottom: 11px !important;
}
.m-11 {
  margin: 11px;
}
.m-r-12 {
  margin-right: 12px !important;
}
.m-l-12 {
  margin-left: 12px !important;
}
.m-t-12 {
  margin-top: 12px !important;
}
.m-b-12 {
  margin-bottom: 12px !important;
}
.m-12 {
  margin: 12px;
}
.m-r-13 {
  margin-right: 13px !important;
}
.m-l-13 {
  margin-left: 13px !important;
}
.m-t-13 {
  margin-top: 13px !important;
}
.m-b-13 {
  margin-bottom: 13px !important;
}
.m-13 {
  margin: 13px;
}
.m-r-14 {
  margin-right: 14px !important;
}
.m-l-14 {
  margin-left: 14px !important;
}
.m-t-14 {
  margin-top: 14px !important;
}
.m-b-14 {
  margin-bottom: 14px !important;
}
.m-14 {
  margin: 14px;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-15 {
  margin: 15px;
}
.m-r-16 {
  margin-right: 16px !important;
}
.m-l-16 {
  margin-left: 16px !important;
}
.m-t-16 {
  margin-top: 16px !important;
}
.m-b-16 {
  margin-bottom: 16px !important;
}
.m-16 {
  margin: 16px;
}
.m-r-17 {
  margin-right: 17px !important;
}
.m-l-17 {
  margin-left: 17px !important;
}
.m-t-17 {
  margin-top: 17px !important;
}
.m-b-17 {
  margin-bottom: 17px !important;
}
.m-17 {
  margin: 17px;
}
.m-r-18 {
  margin-right: 18px !important;
}
.m-l-18 {
  margin-left: 18px !important;
}
.m-t-18 {
  margin-top: 18px !important;
}
.m-b-18 {
  margin-bottom: 18px !important;
}
.m-18 {
  margin: 18px;
}
.m-r-19 {
  margin-right: 19px !important;
}
.m-l-19 {
  margin-left: 19px !important;
}
.m-t-19 {
  margin-top: 19px !important;
}
.m-b-19 {
  margin-bottom: 19px !important;
}
.m-19 {
  margin: 19px;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-20 {
  margin: 20px;
}
.m-r-21 {
  margin-right: 21px !important;
}
.m-l-21 {
  margin-left: 21px !important;
}
.m-t-21 {
  margin-top: 21px !important;
}
.m-b-21 {
  margin-bottom: 21px !important;
}
.m-21 {
  margin: 21px;
}
.m-r-22 {
  margin-right: 22px !important;
}
.m-l-22 {
  margin-left: 22px !important;
}
.m-t-22 {
  margin-top: 22px !important;
}
.m-b-22 {
  margin-bottom: 22px !important;
}
.m-22 {
  margin: 22px;
}
.m-r-23 {
  margin-right: 23px !important;
}
.m-l-23 {
  margin-left: 23px !important;
}
.m-t-23 {
  margin-top: 23px !important;
}
.m-b-23 {
  margin-bottom: 23px !important;
}
.m-23 {
  margin: 23px;
}
.m-r-24 {
  margin-right: 24px !important;
}
.m-l-24 {
  margin-left: 24px !important;
}
.m-t-24 {
  margin-top: 24px !important;
}
.m-b-24 {
  margin-bottom: 24px !important;
}
.m-24 {
  margin: 24px;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-25 {
  margin: 25px;
}
.m-r-26 {
  margin-right: 26px !important;
}
.m-l-26 {
  margin-left: 26px !important;
}
.m-t-26 {
  margin-top: 26px !important;
}
.m-b-26 {
  margin-bottom: 26px !important;
}
.m-26 {
  margin: 26px;
}
.m-r-27 {
  margin-right: 27px !important;
}
.m-l-27 {
  margin-left: 27px !important;
}
.m-t-27 {
  margin-top: 27px !important;
}
.m-b-27 {
  margin-bottom: 27px !important;
}
.m-27 {
  margin: 27px;
}
.m-r-28 {
  margin-right: 28px !important;
}
.m-l-28 {
  margin-left: 28px !important;
}
.m-t-28 {
  margin-top: 28px !important;
}
.m-b-28 {
  margin-bottom: 28px !important;
}
.m-28 {
  margin: 28px;
}
.m-r-29 {
  margin-right: 29px !important;
}
.m-l-29 {
  margin-left: 29px !important;
}
.m-t-29 {
  margin-top: 29px !important;
}
.m-b-29 {
  margin-bottom: 29px !important;
}
.m-29 {
  margin: 29px;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-30 {
  margin: 30px;
}
.m-r-31 {
  margin-right: 31px !important;
}
.m-l-31 {
  margin-left: 31px !important;
}
.m-t-31 {
  margin-top: 31px !important;
}
.m-b-31 {
  margin-bottom: 31px !important;
}
.m-31 {
  margin: 31px;
}
.m-r-32 {
  margin-right: 32px !important;
}
.m-l-32 {
  margin-left: 32px !important;
}
.m-t-32 {
  margin-top: 32px !important;
}
.m-b-32 {
  margin-bottom: 32px !important;
}
.m-32 {
  margin: 32px;
}
.m-r-33 {
  margin-right: 33px !important;
}
.m-l-33 {
  margin-left: 33px !important;
}
.m-t-33 {
  margin-top: 33px !important;
}
.m-b-33 {
  margin-bottom: 33px !important;
}
.m-33 {
  margin: 33px;
}
.m-r-34 {
  margin-right: 34px !important;
}
.m-l-34 {
  margin-left: 34px !important;
}
.m-t-34 {
  margin-top: 34px !important;
}
.m-b-34 {
  margin-bottom: 34px !important;
}
.m-34 {
  margin: 34px;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-35 {
  margin: 35px;
}
.m-r-36 {
  margin-right: 36px !important;
}
.m-l-36 {
  margin-left: 36px !important;
}
.m-t-36 {
  margin-top: 36px !important;
}
.m-b-36 {
  margin-bottom: 36px !important;
}
.m-36 {
  margin: 36px;
}
.m-r-37 {
  margin-right: 37px !important;
}
.m-l-37 {
  margin-left: 37px !important;
}
.m-t-37 {
  margin-top: 37px !important;
}
.m-b-37 {
  margin-bottom: 37px !important;
}
.m-37 {
  margin: 37px;
}
.m-r-38 {
  margin-right: 38px !important;
}
.m-l-38 {
  margin-left: 38px !important;
}
.m-t-38 {
  margin-top: 38px !important;
}
.m-b-38 {
  margin-bottom: 38px !important;
}
.m-38 {
  margin: 38px;
}
.m-r-39 {
  margin-right: 39px !important;
}
.m-l-39 {
  margin-left: 39px !important;
}
.m-t-39 {
  margin-top: 39px !important;
}
.m-b-39 {
  margin-bottom: 39px !important;
}
.m-39 {
  margin: 39px;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-40 {
  margin: 40px;
}
.m-r-42 {
  margin-right: 42px !important;
}
.m-l-42 {
  margin-left: 42px !important;
}
.m-t-42 {
  margin-top: 42px !important;
}
.m-b-42 {
  margin-bottom: 42px !important;
}
.m-42 {
  margin: 42px;
}
.m-r-44 {
  margin-right: 44px !important;
}
.m-l-44 {
  margin-left: 44px !important;
}
.m-t-44 {
  margin-top: 44px !important;
}
.m-b-44 {
  margin-bottom: 44px !important;
}
.m-44 {
  margin: 44px;
}
.m-r-45 {
  margin-right: 45px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}
.m-t-45 {
  margin-top: 45px !important;
}
.m-b-45 {
  margin-bottom: 45px !important;
}
.m-45 {
  margin: 45px;
}
.m-r-46 {
  margin-right: 46px !important;
}
.m-l-46 {
  margin-left: 46px !important;
}
.m-t-46 {
  margin-top: 46px !important;
}
.m-b-46 {
  margin-bottom: 46px !important;
}
.m-46 {
  margin: 46px;
}
.m-r-48 {
  margin-right: 48px !important;
}
.m-l-48 {
  margin-left: 48px !important;
}
.m-t-48 {
  margin-top: 48px !important;
}
.m-b-48 {
  margin-bottom: 48px !important;
}
.m-48 {
  margin: 48px;
}
.m-r-50 {
  margin-right: 50px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.m-50 {
  margin: 50px;
}
.m-r-52 {
  margin-right: 52px !important;
}
.m-l-52 {
  margin-left: 52px !important;
}
.m-t-52 {
  margin-top: 52px !important;
}
.m-b-52 {
  margin-bottom: 52px !important;
}
.m-52 {
  margin: 52px;
}
.m-r-54 {
  margin-right: 54px !important;
}
.m-l-54 {
  margin-left: 54px !important;
}
.m-t-54 {
  margin-top: 54px !important;
}
.m-b-54 {
  margin-bottom: 54px !important;
}
.m-54 {
  margin: 54px;
}
.m-r-55 {
  margin-right: 55px !important;
}
.m-l-55 {
  margin-left: 55px !important;
}
.m-t-55 {
  margin-top: 55px !important;
}
.m-b-55 {
  margin-bottom: 55px !important;
}
.m-55 {
  margin: 55px;
}
.m-r-56 {
  margin-right: 56px !important;
}
.m-l-56 {
  margin-left: 56px !important;
}
.m-t-56 {
  margin-top: 56px !important;
}
.m-b-56 {
  margin-bottom: 56px !important;
}
.m-56 {
  margin: 56px;
}
.m-r-58 {
  margin-right: 58px !important;
}
.m-l-58 {
  margin-left: 58px !important;
}
.m-t-58 {
  margin-top: 58px !important;
}
.m-b-58 {
  margin-bottom: 58px !important;
}
.m-58 {
  margin: 58px;
}
.m-r-60 {
  margin-right: 60px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-t-60 {
  margin-top: 60px !important;
}
.m-b-60 {
  margin-bottom: 60px !important;
}
.m-60 {
  margin: 60px;
}
.m-r-62 {
  margin-right: 62px !important;
}
.m-l-62 {
  margin-left: 62px !important;
}
.m-t-62 {
  margin-top: 62px !important;
}
.m-b-62 {
  margin-bottom: 62px !important;
}
.m-62 {
  margin: 62px;
}
.m-r-64 {
  margin-right: 64px !important;
}
.m-l-64 {
  margin-left: 64px !important;
}
.m-t-64 {
  margin-top: 64px !important;
}
.m-b-64 {
  margin-bottom: 64px !important;
}
.m-64 {
  margin: 64px;
}
.m-r-65 {
  margin-right: 65px !important;
}
.m-l-65 {
  margin-left: 65px !important;
}
.m-t-65 {
  margin-top: 65px !important;
}
.m-b-65 {
  margin-bottom: 65px !important;
}
.m-65 {
  margin: 65px;
}
.m-r-66 {
  margin-right: 66px !important;
}
.m-l-66 {
  margin-left: 66px !important;
}
.m-t-66 {
  margin-top: 66px !important;
}
.m-b-66 {
  margin-bottom: 66px !important;
}
.m-66 {
  margin: 66px;
}
.m-r-68 {
  margin-right: 68px !important;
}
.m-l-68 {
  margin-left: 68px !important;
}
.m-t-68 {
  margin-top: 68px !important;
}
.m-b-68 {
  margin-bottom: 68px !important;
}
.m-68 {
  margin: 68px;
}
.m-r-700 {
  margin-right: 700px !important;
}
.m-l-700 {
  margin-left: 700px !important;
}
.m-t-700 {
  margin-top: 700px !important;
}
.m-b-700 {
  margin-bottom: 700px !important;
}
.m-700 {
  margin: 700px;
}
.m-r-72 {
  margin-right: 72px !important;
}
.m-l-72 {
  margin-left: 72px !important;
}
.m-t-72 {
  margin-top: 72px !important;
}
.m-b-72 {
  margin-bottom: 72px !important;
}
.m-72 {
  margin: 72px;
}
.m-r-74 {
  margin-right: 74px !important;
}
.m-l-74 {
  margin-left: 74px !important;
}
.m-t-74 {
  margin-top: 74px !important;
}
.m-b-74 {
  margin-bottom: 74px !important;
}
.m-74 {
  margin: 74px;
}
.m-r-75 {
  margin-right: 75px !important;
}
.m-l-75 {
  margin-left: 75px !important;
}
.m-t-75 {
  margin-top: 75px !important;
}
.m-b-75 {
  margin-bottom: 75px !important;
}
.m-75 {
  margin: 75px;
}
.m-r-76 {
  margin-right: 76px !important;
}
.m-l-76 {
  margin-left: 76px !important;
}
.m-t-76 {
  margin-top: 76px !important;
}
.m-b-76 {
  margin-bottom: 76px !important;
}
.m-76 {
  margin: 76px;
}
.m-r-78 {
  margin-right: 78px !important;
}
.m-l-78 {
  margin-left: 78px !important;
}
.m-t-78 {
  margin-top: 78px !important;
}
.m-b-78 {
  margin-bottom: 78px !important;
}
.m-78 {
  margin: 78px;
}
.m-r-80 {
  margin-right: 80px !important;
}
.m-l-80 {
  margin-left: 80px !important;
}
.m-t-80 {
  margin-top: 80px !important;
}
.m-b-80 {
  margin-bottom: 80px !important;
}
.m-80 {
  margin: 80px;
}

.p-r-0 {
  padding-right: 0px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-0 {
  padding: 0px;
}
.p-r-1 {
  padding-right: 1px !important;
}
.p-l-1 {
  padding-left: 1px !important;
}
.p-t-1 {
  padding-top: 1px !important;
}
.p-b-1 {
  padding-bottom: 1px !important;
}
.p-1 {
  padding: 1px;
}
.p-r-2 {
  padding-right: 2px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.p-t-2 {
  padding-top: 2px !important;
}
.p-b-2 {
  padding-bottom: 2px !important;
}
.p-2 {
  padding: 2px;
}
.p-r-3 {
  padding-right: 3px !important;
}
.p-l-3 {
  padding-left: 3px !important;
}
.p-t-3 {
  padding-top: 3px !important;
}
.p-b-3 {
  padding-bottom: 3px !important;
}
.p-3 {
  padding: 3px;
}
.p-r-4 {
  padding-right: 4px !important;
}
.p-l-4 {
  padding-left: 4px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-b-4 {
  padding-bottom: 4px !important;
}
.p-4 {
  padding: 4px;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-5 {
  padding: 5px;
}
.p-r-6 {
  padding-right: 6px !important;
}
.p-l-6 {
  padding-left: 6px !important;
}
.p-t-6 {
  padding-top: 6px !important;
}
.p-b-6 {
  padding-bottom: 6px !important;
}
.p-6 {
  padding: 6px;
}
.p-r-7 {
  padding-right: 7px !important;
}
.p-l-7 {
  padding-left: 7px !important;
}
.p-t-7 {
  padding-top: 7px !important;
}
.p-b-7 {
  padding-bottom: 7px !important;
}
.p-7 {
  padding: 7px;
}
.p-r-8 {
  padding-right: 8px !important;
}
.p-l-8 {
  padding-left: 8px !important;
}
.p-t-8 {
  padding-top: 8px !important;
}
.p-b-8 {
  padding-bottom: 8px !important;
}
.p-8 {
  padding: 8px;
}
.p-r-9 {
  padding-right: 9px !important;
}
.p-l-9 {
  padding-left: 9px !important;
}
.p-t-9 {
  padding-top: 9px !important;
}
.p-b-9 {
  padding-bottom: 9px !important;
}
.p-9 {
  padding: 9px;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-10 {
  padding: 10px;
}
.p-r-11 {
  padding-right: 11px !important;
}
.p-l-11 {
  padding-left: 11px !important;
}
.p-t-11 {
  padding-top: 11px !important;
}
.p-b-11 {
  padding-bottom: 11px !important;
}
.p-11 {
  padding: 11px;
}
.p-r-12 {
  padding-right: 12px !important;
}
.p-l-12 {
  padding-left: 12px !important;
}
.p-t-12 {
  padding-top: 12px !important;
}
.p-b-12 {
  padding-bottom: 12px !important;
}
.p-12 {
  padding: 12px;
}
.p-r-13 {
  padding-right: 13px !important;
}
.p-l-13 {
  padding-left: 13px !important;
}
.p-t-13 {
  padding-top: 13px !important;
}
.p-b-13 {
  padding-bottom: 13px !important;
}
.p-13 {
  padding: 13px;
}
.p-r-14 {
  padding-right: 14px !important;
}
.p-l-14 {
  padding-left: 14px !important;
}
.p-t-14 {
  padding-top: 14px !important;
}
.p-b-14 {
  padding-bottom: 14px !important;
}
.p-14 {
  padding: 14px;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-15 {
  padding: 15px;
}
.p-r-16 {
  padding-right: 16px !important;
}
.p-l-16 {
  padding-left: 16px !important;
}
.p-t-16 {
  padding-top: 16px !important;
}
.p-b-16 {
  padding-bottom: 16px !important;
}
.p-16 {
  padding: 16px;
}
.p-r-17 {
  padding-right: 17px !important;
}
.p-l-17 {
  padding-left: 17px !important;
}
.p-t-17 {
  padding-top: 17px !important;
}
.p-b-17 {
  padding-bottom: 17px !important;
}
.p-17 {
  padding: 17px;
}
.p-r-18 {
  padding-right: 18px !important;
}
.p-l-18 {
  padding-left: 18px !important;
}
.p-t-18 {
  padding-top: 18px !important;
}
.p-b-18 {
  padding-bottom: 18px !important;
}
.p-18 {
  padding: 18px;
}
.p-r-19 {
  padding-right: 19px !important;
}
.p-l-19 {
  padding-left: 19px !important;
}
.p-t-19 {
  padding-top: 19px !important;
}
.p-b-19 {
  padding-bottom: 19px !important;
}
.p-19 {
  padding: 19px;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-20 {
  padding: 20px;
}
.p-r-21 {
  padding-right: 21px !important;
}
.p-l-21 {
  padding-left: 21px !important;
}
.p-t-21 {
  padding-top: 21px !important;
}
.p-b-21 {
  padding-bottom: 21px !important;
}
.p-21 {
  padding: 21px;
}
.p-r-22 {
  padding-right: 22px !important;
}
.p-l-22 {
  padding-left: 22px !important;
}
.p-t-22 {
  padding-top: 22px !important;
}
.p-b-22 {
  padding-bottom: 22px !important;
}
.p-22 {
  padding: 22px;
}
.p-r-23 {
  padding-right: 23px !important;
}
.p-l-23 {
  padding-left: 23px !important;
}
.p-t-23 {
  padding-top: 23px !important;
}
.p-b-23 {
  padding-bottom: 23px !important;
}
.p-23 {
  padding: 23px;
}
.p-r-24 {
  padding-right: 24px !important;
}
.p-l-24 {
  padding-left: 24px !important;
}
.p-t-24 {
  padding-top: 24px !important;
}
.p-b-24 {
  padding-bottom: 24px !important;
}
.p-24 {
  padding: 24px;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-25 {
  padding: 25px;
}
.p-r-26 {
  padding-right: 26px !important;
}
.p-l-26 {
  padding-left: 26px !important;
}
.p-t-26 {
  padding-top: 26px !important;
}
.p-b-26 {
  padding-bottom: 26px !important;
}
.p-26 {
  padding: 26px;
}
.p-r-27 {
  padding-right: 27px !important;
}
.p-l-27 {
  padding-left: 27px !important;
}
.p-t-27 {
  padding-top: 27px !important;
}
.p-b-27 {
  padding-bottom: 27px !important;
}
.p-27 {
  padding: 27px;
}
.p-r-28 {
  padding-right: 28px !important;
}
.p-l-28 {
  padding-left: 28px !important;
}
.p-t-28 {
  padding-top: 28px !important;
}
.p-b-28 {
  padding-bottom: 28px !important;
}
.p-28 {
  padding: 28px;
}
.p-r-29 {
  padding-right: 29px !important;
}
.p-l-29 {
  padding-left: 29px !important;
}
.p-t-29 {
  padding-top: 29px !important;
}
.p-b-29 {
  padding-bottom: 29px !important;
}
.p-29 {
  padding: 29px;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-30 {
  padding: 30px;
}
.p-r-31 {
  padding-right: 31px !important;
}
.p-l-31 {
  padding-left: 31px !important;
}
.p-t-31 {
  padding-top: 31px !important;
}
.p-b-31 {
  padding-bottom: 31px !important;
}
.p-31 {
  padding: 31px;
}
.p-r-32 {
  padding-right: 32px !important;
}
.p-l-32 {
  padding-left: 32px !important;
}
.p-t-32 {
  padding-top: 32px !important;
}
.p-b-32 {
  padding-bottom: 32px !important;
}
.p-32 {
  padding: 32px;
}
.p-r-33 {
  padding-right: 33px !important;
}
.p-l-33 {
  padding-left: 33px !important;
}
.p-t-33 {
  padding-top: 33px !important;
}
.p-b-33 {
  padding-bottom: 33px !important;
}
.p-33 {
  padding: 33px;
}
.p-r-34 {
  padding-right: 34px !important;
}
.p-l-34 {
  padding-left: 34px !important;
}
.p-t-34 {
  padding-top: 34px !important;
}
.p-b-34 {
  padding-bottom: 34px !important;
}
.p-34 {
  padding: 34px;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-35 {
  padding: 35px;
}
.p-r-36 {
  padding-right: 36px !important;
}
.p-l-36 {
  padding-left: 36px !important;
}
.p-t-36 {
  padding-top: 36px !important;
}
.p-b-36 {
  padding-bottom: 36px !important;
}
.p-36 {
  padding: 36px;
}
.p-r-37 {
  padding-right: 37px !important;
}
.p-l-37 {
  padding-left: 37px !important;
}
.p-t-37 {
  padding-top: 37px !important;
}
.p-b-37 {
  padding-bottom: 37px !important;
}
.p-37 {
  padding: 37px;
}
.p-r-38 {
  padding-right: 38px !important;
}
.p-l-38 {
  padding-left: 38px !important;
}
.p-t-38 {
  padding-top: 38px !important;
}
.p-b-38 {
  padding-bottom: 38px !important;
}
.p-38 {
  padding: 38px;
}
.p-r-39 {
  padding-right: 39px !important;
}
.p-l-39 {
  padding-left: 39px !important;
}
.p-t-39 {
  padding-top: 39px !important;
}
.p-b-39 {
  padding-bottom: 39px !important;
}
.p-39 {
  padding: 39px;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-40 {
  padding: 40px;
}
.p-r-42 {
  padding-right: 42px !important;
}
.p-l-42 {
  padding-left: 42px !important;
}
.p-t-42 {
  padding-top: 42px !important;
}
.p-b-42 {
  padding-bottom: 42px !important;
}
.p-42 {
  padding: 42px;
}
.p-r-44 {
  padding-right: 44px !important;
}
.p-l-44 {
  padding-left: 44px !important;
}
.p-t-44 {
  padding-top: 44px !important;
}
.p-b-44 {
  padding-bottom: 44px !important;
}
.p-44 {
  padding: 44px;
}
.p-r-45 {
  padding-right: 45px !important;
}
.p-l-45 {
  padding-left: 45px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.p-45 {
  padding: 45px;
}
.p-r-46 {
  padding-right: 46px !important;
}
.p-l-46 {
  padding-left: 46px !important;
}
.p-t-46 {
  padding-top: 46px !important;
}
.p-b-46 {
  padding-bottom: 46px !important;
}
.p-46 {
  padding: 46px;
}
.p-r-48 {
  padding-right: 48px !important;
}
.p-l-48 {
  padding-left: 48px !important;
}
.p-t-48 {
  padding-top: 48px !important;
}
.p-b-48 {
  padding-bottom: 48px !important;
}
.p-48 {
  padding: 48px;
}
.p-r-50 {
  padding-right: 50px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.p-50 {
  padding: 50px;
}
.p-r-52 {
  padding-right: 52px !important;
}
.p-l-52 {
  padding-left: 52px !important;
}
.p-t-52 {
  padding-top: 52px !important;
}
.p-b-52 {
  padding-bottom: 52px !important;
}
.p-52 {
  padding: 52px;
}
.p-r-54 {
  padding-right: 54px !important;
}
.p-l-54 {
  padding-left: 54px !important;
}
.p-t-54 {
  padding-top: 54px !important;
}
.p-b-54 {
  padding-bottom: 54px !important;
}
.p-54 {
  padding: 54px;
}
.p-r-55 {
  padding-right: 55px !important;
}
.p-l-55 {
  padding-left: 55px !important;
}
.p-t-55 {
  padding-top: 55px !important;
}
.p-b-55 {
  padding-bottom: 55px !important;
}
.p-55 {
  padding: 55px;
}
.p-r-56 {
  padding-right: 56px !important;
}
.p-l-56 {
  padding-left: 56px !important;
}
.p-t-56 {
  padding-top: 56px !important;
}
.p-b-56 {
  padding-bottom: 56px !important;
}
.p-56 {
  padding: 56px;
}
.p-r-58 {
  padding-right: 58px !important;
}
.p-l-58 {
  padding-left: 58px !important;
}
.p-t-58 {
  padding-top: 58px !important;
}
.p-b-58 {
  padding-bottom: 58px !important;
}
.p-58 {
  padding: 58px;
}
.p-r-60 {
  padding-right: 60px !important;
}
.p-l-60 {
  padding-left: 60px !important;
}
.p-t-60 {
  padding-top: 60px !important;
}
.p-b-60 {
  padding-bottom: 60px !important;
}
.p-60 {
  padding: 60px;
}
.p-r-62 {
  padding-right: 62px !important;
}
.p-l-62 {
  padding-left: 62px !important;
}
.p-t-62 {
  padding-top: 62px !important;
}
.p-b-62 {
  padding-bottom: 62px !important;
}
.p-62 {
  padding: 62px;
}
.p-r-64 {
  padding-right: 64px !important;
}
.p-l-64 {
  padding-left: 64px !important;
}
.p-t-64 {
  padding-top: 64px !important;
}
.p-b-64 {
  padding-bottom: 64px !important;
}
.p-64 {
  padding: 64px;
}
.p-r-65 {
  padding-right: 65px !important;
}
.p-l-65 {
  padding-left: 65px !important;
}
.p-t-65 {
  padding-top: 65px !important;
}
.p-b-65 {
  padding-bottom: 65px !important;
}
.p-65 {
  padding: 65px;
}
.p-r-66 {
  padding-right: 66px !important;
}
.p-l-66 {
  padding-left: 66px !important;
}
.p-t-66 {
  padding-top: 66px !important;
}
.p-b-66 {
  padding-bottom: 66px !important;
}
.p-66 {
  padding: 66px;
}
.p-r-68 {
  padding-right: 68px !important;
}
.p-l-68 {
  padding-left: 68px !important;
}
.p-t-68 {
  padding-top: 68px !important;
}
.p-b-68 {
  padding-bottom: 68px !important;
}
.p-68 {
  padding: 68px;
}
.p-r-700 {
  padding-right: 700px !important;
}
.p-l-700 {
  padding-left: 700px !important;
}
.p-t-700 {
  padding-top: 700px !important;
}
.p-b-700 {
  padding-bottom: 700px !important;
}
.p-700 {
  padding: 700px;
}
.p-r-72 {
  padding-right: 72px !important;
}
.p-l-72 {
  padding-left: 72px !important;
}
.p-t-72 {
  padding-top: 72px !important;
}
.p-b-72 {
  padding-bottom: 72px !important;
}
.p-72 {
  padding: 72px;
}
.p-r-74 {
  padding-right: 74px !important;
}
.p-l-74 {
  padding-left: 74px !important;
}
.p-t-74 {
  padding-top: 74px !important;
}
.p-b-74 {
  padding-bottom: 74px !important;
}
.p-74 {
  padding: 74px;
}
.p-r-75 {
  padding-right: 75px !important;
}
.p-l-75 {
  padding-left: 75px !important;
}
.p-t-75 {
  padding-top: 75px !important;
}
.p-b-75 {
  padding-bottom: 75px !important;
}
.p-75 {
  padding: 75px;
}
.p-r-76 {
  padding-right: 76px !important;
}
.p-l-76 {
  padding-left: 76px !important;
}
.p-t-76 {
  padding-top: 76px !important;
}
.p-b-76 {
  padding-bottom: 76px !important;
}
.p-76 {
  padding: 76px;
}
.p-r-78 {
  padding-right: 78px !important;
}
.p-l-78 {
  padding-left: 78px !important;
}
.p-t-78 {
  padding-top: 78px !important;
}
.p-b-78 {
  padding-bottom: 78px !important;
}
.p-78 {
  padding: 78px;
}
.p-r-80 {
  padding-right: 80px !important;
}
.p-l-80 {
  padding-left: 80px !important;
}
.p-t-80 {
  padding-top: 80px !important;
}
.p-b-80 {
  padding-bottom: 80px !important;
}
.p-80 {
  padding: 80px;
}

.nm-pl-0 {
  padding-left: 0px !important;
}

.nm-pl-8 {
  padding-left: 8px !important;
}

.nm-pl-16 {
  padding-left: 16px !important;
}

.nm-pl-24 {
  padding-left: 24px !important;
}

.nm-pl-32 {
  padding-left: 32px !important;
}

.nm-pl-40 {
  padding-left: 40px !important;
}

.nm-pl-48 {
  padding-left: 48px !important;
}

.nm-pl-56 {
  padding-left: 56px !important;
}

.nm-pl-64 {
  padding-left: 64px !important;
}

.nm-pl-72 {
  padding-left: 72px !important;
}

.nm-pl-80 {
  padding-left: 80px !important;
}

.nm-pl-88 {
  padding-left: 88px !important;
}

.nm-pl-96 {
  padding-left: 96px !important;
}

.nm-pl-104 {
  padding-left: 104px !important;
}

.nm-pl-112 {
  padding-left: 112px !important;
}

.nm-pl-120 {
  padding-left: 120px !important;
}

.nm-pl-128 {
  padding-left: 128px !important;
}

.nm-pl-136 {
  padding-left: 136px !important;
}

.nm-pl-144 {
  padding-left: 144px !important;
}

.nm-pl-152 {
  padding-left: 152px !important;
}

.nm-pl-160 {
  padding-left: 160px !important;
}

.nm-pr-0 {
  padding-right: 0px !important;
}

.nm-pr-8 {
  padding-right: 8px !important;
}

.nm-pr-16 {
  padding-right: 16px !important;
}

.nm-pr-24 {
  padding-right: 24px !important;
}

.nm-pr-32 {
  padding-right: 32px !important;
}

.nm-pr-40 {
  padding-right: 40px !important;
}

.nm-pr-48 {
  padding-right: 48px !important;
}

.nm-pr-56 {
  padding-right: 56px !important;
}

.nm-pr-64 {
  padding-right: 64px !important;
}

.nm-pr-72 {
  padding-right: 72px !important;
}

.nm-pr-80 {
  padding-right: 80px !important;
}

.nm-pr-88 {
  padding-right: 88px !important;
}

.nm-pr-96 {
  padding-right: 96px !important;
}

.nm-pr-104 {
  padding-right: 104px !important;
}

.nm-pr-112 {
  padding-right: 112px !important;
}

.nm-pr-120 {
  padding-right: 120px !important;
}

.nm-pr-128 {
  padding-right: 128px !important;
}

.nm-pr-136 {
  padding-right: 136px !important;
}

.nm-pr-144 {
  padding-right: 144px !important;
}

.nm-pr-152 {
  padding-right: 152px !important;
}

.nm-pr-160 {
  padding-right: 160px !important;
}

.nm-pt-0 {
  padding-top: 0px !important;
}

.nm-pt-8 {
  padding-top: 8px !important;
}

.nm-pt-16 {
  padding-top: 16px !important;
}

.nm-pt-24 {
  padding-top: 24px !important;
}

.nm-pt-32 {
  padding-top: 32px !important;
}

.nm-pt-40 {
  padding-top: 40px !important;
}

.nm-pt-48 {
  padding-top: 48px !important;
}

.nm-pt-56 {
  padding-top: 56px !important;
}

.nm-pt-64 {
  padding-top: 64px !important;
}

.nm-pt-72 {
  padding-top: 72px !important;
}

.nm-pt-80 {
  padding-top: 80px !important;
}

.nm-pt-88 {
  padding-top: 88px !important;
}

.nm-pt-96 {
  padding-top: 96px !important;
}

.nm-pt-104 {
  padding-top: 104px !important;
}

.nm-pt-112 {
  padding-top: 112px !important;
}

.nm-pt-120 {
  padding-top: 120px !important;
}

.nm-pt-128 {
  padding-top: 128px !important;
}

.nm-pt-136 {
  padding-top: 136px !important;
}

.nm-pt-144 {
  padding-top: 144px !important;
}

.nm-pt-152 {
  padding-top: 152px !important;
}

.nm-pt-160 {
  padding-top: 160px !important;
}

.nm-pb-0 {
  padding-bottom: 0px !important;
}

.nm-pb-8 {
  padding-bottom: 8px !important;
}

.nm-pb-16 {
  padding-bottom: 16px !important;
}

.nm-pb-24 {
  padding-bottom: 24px !important;
}

.nm-pb-32 {
  padding-bottom: 32px !important;
}

.nm-pb-40 {
  padding-bottom: 40px !important;
}

.nm-pb-48 {
  padding-bottom: 48px !important;
}

.nm-pb-56 {
  padding-bottom: 56px !important;
}

.nm-pb-64 {
  padding-bottom: 64px !important;
}

.nm-pb-72 {
  padding-bottom: 72px !important;
}

.nm-pb-80 {
  padding-bottom: 80px !important;
}

.nm-pb-88 {
  padding-bottom: 88px !important;
}

.nm-pb-96 {
  padding-bottom: 96px !important;
}

.nm-pb-104 {
  padding-bottom: 104px !important;
}

.nm-pb-112 {
  padding-bottom: 112px !important;
}

.nm-pb-120 {
  padding-bottom: 120px !important;
}

.nm-pb-128 {
  padding-bottom: 128px !important;
}

.nm-pb-136 {
  padding-bottom: 136px !important;
}

.nm-pb-144 {
  padding-bottom: 144px !important;
}

.nm-pb-152 {
  padding-bottom: 152px !important;
}

.nm-pb-160 {
  padding-bottom: 160px !important;
}

.nm-px-0 {
  padding-left: 0px !important;
}

.nm-px-0 {
  padding-right: 0px !important;
}

.nm-px-8 {
  padding-left: 8px !important;
}

.nm-px-8 {
  padding-right: 8px !important;
}

.nm-px-16 {
  padding-left: 16px !important;
}

.nm-px-16 {
  padding-right: 16px !important;
}

.nm-px-24 {
  padding-left: 24px !important;
}

.nm-px-24 {
  padding-right: 24px !important;
}

.nm-px-32 {
  padding-left: 32px !important;
}

.nm-px-32 {
  padding-right: 32px !important;
}

.nm-px-40 {
  padding-left: 40px !important;
}

.nm-px-40 {
  padding-right: 40px !important;
}

.nm-px-48 {
  padding-left: 48px !important;
}

.nm-px-48 {
  padding-right: 48px !important;
}

.nm-px-56 {
  padding-left: 56px !important;
}

.nm-px-56 {
  padding-right: 56px !important;
}

.nm-px-64 {
  padding-left: 64px !important;
}

.nm-px-64 {
  padding-right: 64px !important;
}

.nm-px-72 {
  padding-left: 72px !important;
}

.nm-px-72 {
  padding-right: 72px !important;
}

.nm-px-80 {
  padding-left: 80px !important;
}

.nm-px-80 {
  padding-right: 80px !important;
}

.nm-px-88 {
  padding-left: 88px !important;
}

.nm-px-88 {
  padding-right: 88px !important;
}

.nm-px-96 {
  padding-left: 96px !important;
}

.nm-px-96 {
  padding-right: 96px !important;
}

.nm-px-104 {
  padding-left: 104px !important;
}

.nm-px-104 {
  padding-right: 104px !important;
}

.nm-px-112 {
  padding-left: 112px !important;
}

.nm-px-112 {
  padding-right: 112px !important;
}

.nm-px-120 {
  padding-left: 120px !important;
}

.nm-px-120 {
  padding-right: 120px !important;
}

.nm-px-128 {
  padding-left: 128px !important;
}

.nm-px-128 {
  padding-right: 128px !important;
}

.nm-px-136 {
  padding-left: 136px !important;
}

.nm-px-136 {
  padding-right: 136px !important;
}

.nm-px-144 {
  padding-left: 144px !important;
}

.nm-px-144 {
  padding-right: 144px !important;
}

.nm-px-152 {
  padding-left: 152px !important;
}

.nm-px-152 {
  padding-right: 152px !important;
}

.nm-px-160 {
  padding-left: 160px !important;
}

.nm-px-160 {
  padding-right: 160px !important;
}

.nm-py-0 {
  padding-bottom: 0px !important;
}

.nm-py-0 {
  padding-top: 0px !important;
}

.nm-py-8 {
  padding-bottom: 8px !important;
}

.nm-py-8 {
  padding-top: 8px !important;
}

.nm-py-16 {
  padding-bottom: 16px !important;
}

.nm-py-16 {
  padding-top: 16px !important;
}

.nm-py-24 {
  padding-bottom: 24px !important;
}

.nm-py-24 {
  padding-top: 24px !important;
}

.nm-py-32 {
  padding-bottom: 32px !important;
}

.nm-py-32 {
  padding-top: 32px !important;
}

.nm-py-40 {
  padding-bottom: 40px !important;
}

.nm-py-40 {
  padding-top: 40px !important;
}

.nm-py-48 {
  padding-bottom: 48px !important;
}

.nm-py-48 {
  padding-top: 48px !important;
}

.nm-py-56 {
  padding-bottom: 56px !important;
}

.nm-py-56 {
  padding-top: 56px !important;
}

.nm-py-64 {
  padding-bottom: 64px !important;
}

.nm-py-64 {
  padding-top: 64px !important;
}

.nm-py-72 {
  padding-bottom: 72px !important;
}

.nm-py-72 {
  padding-top: 72px !important;
}

.nm-py-80 {
  padding-bottom: 80px !important;
}

.nm-py-80 {
  padding-top: 80px !important;
}

.nm-py-88 {
  padding-bottom: 88px !important;
}

.nm-py-88 {
  padding-top: 88px !important;
}

.nm-py-96 {
  padding-bottom: 96px !important;
}

.nm-py-96 {
  padding-top: 96px !important;
}

.nm-py-104 {
  padding-bottom: 104px !important;
}

.nm-py-104 {
  padding-top: 104px !important;
}

.nm-py-112 {
  padding-bottom: 112px !important;
}

.nm-py-112 {
  padding-top: 112px !important;
}

.nm-py-120 {
  padding-bottom: 120px !important;
}

.nm-py-120 {
  padding-top: 120px !important;
}

.nm-py-128 {
  padding-bottom: 128px !important;
}

.nm-py-128 {
  padding-top: 128px !important;
}

.nm-py-136 {
  padding-bottom: 136px !important;
}

.nm-py-136 {
  padding-top: 136px !important;
}

.nm-py-144 {
  padding-bottom: 144px !important;
}

.nm-py-144 {
  padding-top: 144px !important;
}

.nm-py-152 {
  padding-bottom: 152px !important;
}

.nm-py-152 {
  padding-top: 152px !important;
}

.nm-py-160 {
  padding-bottom: 160px !important;
}

.nm-py-160 {
  padding-top: 160px !important;
}

.nm-p-0 {
  padding: 0px !important;
}

.nm-p-8 {
  padding: 8px !important;
}

.nm-p-16 {
  padding: 16px !important;
}

.nm-p-24 {
  padding: 24px !important;
}

.nm-p-32 {
  padding: 32px !important;
}

.nm-p-40 {
  padding: 40px !important;
}

.nm-p-48 {
  padding: 48px !important;
}

.nm-p-56 {
  padding: 56px !important;
}

.nm-p-64 {
  padding: 64px !important;
}

.nm-p-72 {
  padding: 72px !important;
}

.nm-p-80 {
  padding: 80px !important;
}

.nm-p-88 {
  padding: 88px !important;
}

.nm-p-96 {
  padding: 96px !important;
}

.nm-p-104 {
  padding: 104px !important;
}

.nm-p-112 {
  padding: 112px !important;
}

.nm-p-120 {
  padding: 120px !important;
}

.nm-p-128 {
  padding: 128px !important;
}

.nm-p-136 {
  padding: 136px !important;
}

.nm-p-144 {
  padding: 144px !important;
}

.nm-p-152 {
  padding: 152px !important;
}

.nm-p-160 {
  padding: 160px !important;
}

.nm-ml-0 {
  margin-left: 0px !important;
}

.nm-ml-8 {
  margin-left: 8px !important;
}

.nm-ml-16 {
  margin-left: 16px !important;
}

.nm-ml-24 {
  margin-left: 24px !important;
}

.nm-ml-32 {
  margin-left: 32px !important;
}

.nm-ml-40 {
  margin-left: 40px !important;
}

.nm-ml-48 {
  margin-left: 48px !important;
}

.nm-ml-56 {
  margin-left: 56px !important;
}

.nm-ml-64 {
  margin-left: 64px !important;
}

.nm-ml-72 {
  margin-left: 72px !important;
}

.nm-ml-80 {
  margin-left: 80px !important;
}

.nm-ml-88 {
  margin-left: 88px !important;
}

.nm-ml-96 {
  margin-left: 96px !important;
}

.nm-ml-104 {
  margin-left: 104px !important;
}

.nm-ml-112 {
  margin-left: 112px !important;
}

.nm-ml-120 {
  margin-left: 120px !important;
}

.nm-ml-128 {
  margin-left: 128px !important;
}

.nm-ml-136 {
  margin-left: 136px !important;
}

.nm-ml-144 {
  margin-left: 144px !important;
}

.nm-ml-152 {
  margin-left: 152px !important;
}

.nm-ml-160 {
  margin-left: 160px !important;
}

.nm-mr-0 {
  margin-right: 0px !important;
}

.nm-mr-8 {
  margin-right: 8px !important;
}

.nm-mr-16 {
  margin-right: 16px !important;
}

.nm-mr-24 {
  margin-right: 24px !important;
}

.nm-mr-32 {
  margin-right: 32px !important;
}

.nm-mr-40 {
  margin-right: 40px !important;
}

.nm-mr-48 {
  margin-right: 48px !important;
}

.nm-mr-56 {
  margin-right: 56px !important;
}

.nm-mr-64 {
  margin-right: 64px !important;
}

.nm-mr-72 {
  margin-right: 72px !important;
}

.nm-mr-80 {
  margin-right: 80px !important;
}

.nm-mr-88 {
  margin-right: 88px !important;
}

.nm-mr-96 {
  margin-right: 96px !important;
}

.nm-mr-104 {
  margin-right: 104px !important;
}

.nm-mr-112 {
  margin-right: 112px !important;
}

.nm-mr-120 {
  margin-right: 120px !important;
}

.nm-mr-128 {
  margin-right: 128px !important;
}

.nm-mr-136 {
  margin-right: 136px !important;
}

.nm-mr-144 {
  margin-right: 144px !important;
}

.nm-mr-152 {
  margin-right: 152px !important;
}

.nm-mr-160 {
  margin-right: 160px !important;
}

.nm-mt-0 {
  margin-top: 0px !important;
}

.nm-mt-8 {
  margin-top: 8px !important;
}

.nm-mt-16 {
  margin-top: 16px !important;
}

.nm-mt-24 {
  margin-top: 24px !important;
}

.nm-mt-32 {
  margin-top: 32px !important;
}

.nm-mt-40 {
  margin-top: 40px !important;
}

.nm-mt-48 {
  margin-top: 48px !important;
}

.nm-mt-56 {
  margin-top: 56px !important;
}

.nm-mt-64 {
  margin-top: 64px !important;
}

.nm-mt-72 {
  margin-top: 72px !important;
}

.nm-mt-80 {
  margin-top: 80px !important;
}

.nm-mt-88 {
  margin-top: 88px !important;
}

.nm-mt-96 {
  margin-top: 96px !important;
}

.nm-mt-104 {
  margin-top: 104px !important;
}

.nm-mt-112 {
  margin-top: 112px !important;
}

.nm-mt-120 {
  margin-top: 120px !important;
}

.nm-mt-128 {
  margin-top: 128px !important;
}

.nm-mt-136 {
  margin-top: 136px !important;
}

.nm-mt-144 {
  margin-top: 144px !important;
}

.nm-mt-152 {
  margin-top: 152px !important;
}

.nm-mt-160 {
  margin-top: 160px !important;
}

.nm-mb-0 {
  margin-bottom: 0px !important;
}

.nm-mb-8 {
  margin-bottom: 8px !important;
}

.nm-mb-16 {
  margin-bottom: 16px !important;
}

.nm-mb-24 {
  margin-bottom: 24px !important;
}

.nm-mb-32 {
  margin-bottom: 32px !important;
}

.nm-mb-40 {
  margin-bottom: 40px !important;
}

.nm-mb-48 {
  margin-bottom: 48px !important;
}

.nm-mb-56 {
  margin-bottom: 56px !important;
}

.nm-mb-64 {
  margin-bottom: 64px !important;
}

.nm-mb-72 {
  margin-bottom: 72px !important;
}

.nm-mb-80 {
  margin-bottom: 80px !important;
}

.nm-mb-88 {
  margin-bottom: 88px !important;
}

.nm-mb-96 {
  margin-bottom: 96px !important;
}

.nm-mb-104 {
  margin-bottom: 104px !important;
}

.nm-mb-112 {
  margin-bottom: 112px !important;
}

.nm-mb-120 {
  margin-bottom: 120px !important;
}

.nm-mb-128 {
  margin-bottom: 128px !important;
}

.nm-mb-136 {
  margin-bottom: 136px !important;
}

.nm-mb-144 {
  margin-bottom: 144px !important;
}

.nm-mb-152 {
  margin-bottom: 152px !important;
}

.nm-mb-160 {
  margin-bottom: 160px !important;
}

.nm-mx-0 {
  margin-left: 0px !important;
}

.nm-mx-0 {
  margin-right: 0px !important;
}

.nm-mx-8 {
  margin-left: 8px !important;
}

.nm-mx-8 {
  margin-right: 8px !important;
}

.nm-mx-16 {
  margin-left: 16px !important;
}

.nm-mx-16 {
  margin-right: 16px !important;
}

.nm-mx-24 {
  margin-left: 24px !important;
}

.nm-mx-24 {
  margin-right: 24px !important;
}

.nm-mx-32 {
  margin-left: 32px !important;
}

.nm-mx-32 {
  margin-right: 32px !important;
}

.nm-mx-40 {
  margin-left: 40px !important;
}

.nm-mx-40 {
  margin-right: 40px !important;
}

.nm-mx-48 {
  margin-left: 48px !important;
}

.nm-mx-48 {
  margin-right: 48px !important;
}

.nm-mx-56 {
  margin-left: 56px !important;
}

.nm-mx-56 {
  margin-right: 56px !important;
}

.nm-mx-64 {
  margin-left: 64px !important;
}

.nm-mx-64 {
  margin-right: 64px !important;
}

.nm-mx-72 {
  margin-left: 72px !important;
}

.nm-mx-72 {
  margin-right: 72px !important;
}

.nm-mx-80 {
  margin-left: 80px !important;
}

.nm-mx-80 {
  margin-right: 80px !important;
}

.nm-mx-88 {
  margin-left: 88px !important;
}

.nm-mx-88 {
  margin-right: 88px !important;
}

.nm-mx-96 {
  margin-left: 96px !important;
}

.nm-mx-96 {
  margin-right: 96px !important;
}

.nm-mx-104 {
  margin-left: 104px !important;
}

.nm-mx-104 {
  margin-right: 104px !important;
}

.nm-mx-112 {
  margin-left: 112px !important;
}

.nm-mx-112 {
  margin-right: 112px !important;
}

.nm-mx-120 {
  margin-left: 120px !important;
}

.nm-mx-120 {
  margin-right: 120px !important;
}

.nm-mx-128 {
  margin-left: 128px !important;
}

.nm-mx-128 {
  margin-right: 128px !important;
}

.nm-mx-136 {
  margin-left: 136px !important;
}

.nm-mx-136 {
  margin-right: 136px !important;
}

.nm-mx-144 {
  margin-left: 144px !important;
}

.nm-mx-144 {
  margin-right: 144px !important;
}

.nm-mx-152 {
  margin-left: 152px !important;
}

.nm-mx-152 {
  margin-right: 152px !important;
}

.nm-mx-160 {
  margin-left: 160px !important;
}

.nm-mx-160 {
  margin-right: 160px !important;
}

.nm-my-0 {
  margin-bottom: 0px !important;
}

.nm-my-0 {
  margin-top: 0px !important;
}

.nm-my-8 {
  margin-bottom: 8px !important;
}

.nm-my-8 {
  margin-top: 8px !important;
}

.nm-my-16 {
  margin-bottom: 16px !important;
}

.nm-my-16 {
  margin-top: 16px !important;
}

.nm-my-24 {
  margin-bottom: 24px !important;
}

.nm-my-24 {
  margin-top: 24px !important;
}

.nm-my-32 {
  margin-bottom: 32px !important;
}

.nm-my-32 {
  margin-top: 32px !important;
}

.nm-my-40 {
  margin-bottom: 40px !important;
}

.nm-my-40 {
  margin-top: 40px !important;
}

.nm-my-48 {
  margin-bottom: 48px !important;
}

.nm-my-48 {
  margin-top: 48px !important;
}

.nm-my-56 {
  margin-bottom: 56px !important;
}

.nm-my-56 {
  margin-top: 56px !important;
}

.nm-my-64 {
  margin-bottom: 64px !important;
}

.nm-my-64 {
  margin-top: 64px !important;
}

.nm-my-72 {
  margin-bottom: 72px !important;
}

.nm-my-72 {
  margin-top: 72px !important;
}

.nm-my-80 {
  margin-bottom: 80px !important;
}

.nm-my-80 {
  margin-top: 80px !important;
}

.nm-my-88 {
  margin-bottom: 88px !important;
}

.nm-my-88 {
  margin-top: 88px !important;
}

.nm-my-96 {
  margin-bottom: 96px !important;
}

.nm-my-96 {
  margin-top: 96px !important;
}

.nm-my-104 {
  margin-bottom: 104px !important;
}

.nm-my-104 {
  margin-top: 104px !important;
}

.nm-my-112 {
  margin-bottom: 112px !important;
}

.nm-my-112 {
  margin-top: 112px !important;
}

.nm-my-120 {
  margin-bottom: 120px !important;
}

.nm-my-120 {
  margin-top: 120px !important;
}

.nm-my-128 {
  margin-bottom: 128px !important;
}

.nm-my-128 {
  margin-top: 128px !important;
}

.nm-my-136 {
  margin-bottom: 136px !important;
}

.nm-my-136 {
  margin-top: 136px !important;
}

.nm-my-144 {
  margin-bottom: 144px !important;
}

.nm-my-144 {
  margin-top: 144px !important;
}

.nm-my-152 {
  margin-bottom: 152px !important;
}

.nm-my-152 {
  margin-top: 152px !important;
}

.nm-my-160 {
  margin-bottom: 160px !important;
}

.nm-my-160 {
  margin-top: 160px !important;
}

.nm-m-0 {
  margin: 0px !important;
}

.nm-m-8 {
  margin: 8px !important;
}

.nm-m-16 {
  margin: 16px !important;
}

.nm-m-24 {
  margin: 24px !important;
}

.nm-m-32 {
  margin: 32px !important;
}

.nm-m-40 {
  margin: 40px !important;
}

.nm-m-48 {
  margin: 48px !important;
}

.nm-m-56 {
  margin: 56px !important;
}

.nm-m-64 {
  margin: 64px !important;
}

.nm-m-72 {
  margin: 72px !important;
}

.nm-m-80 {
  margin: 80px !important;
}

.nm-m-88 {
  margin: 88px !important;
}

.nm-m-96 {
  margin: 96px !important;
}

.nm-m-104 {
  margin: 104px !important;
}

.nm-m-112 {
  margin: 112px !important;
}

.nm-m-120 {
  margin: 120px !important;
}

.nm-m-128 {
  margin: 128px !important;
}

.nm-m-136 {
  margin: 136px !important;
}

.nm-m-144 {
  margin: 144px !important;
}

.nm-m-152 {
  margin: 152px !important;
}

.nm-m-160 {
  margin: 160px !important;
}

.nm-h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

.nm-h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.nm-h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.nm-h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.nm-text-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.nm-text-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.nm-text-bold-primary {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #bd2e7e;
}

.nm-text-small, .nm-text-input-length, .nm-text-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

.nm-text-extra-small {
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
}

.nm-text-error {
  color: #e25874;
}

.nm-underscored-and-highlighted {
  text-decoration-line: underline;
  color: #bd2e7e;
}

.nm-text-purple {
  color: #bd2e7e;
}

.nm-text-input-length {
  text-align: end;
  padding-right: 8px;
}

.nm-text-regular-title-small {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.nm-text-regular-body-large {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.nm-text-regular-body-medium, .nm-text-link {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nm-text-regular-body-small {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.nm-text-regular-label-medium {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nm-text-regular-label-small {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.nm-text-regular-body-extra-small {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.nm-text-bold-display {
  font-family: "Poppins", sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.nm-text-bold-title-large {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.nm-text-bold-title-medium {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.nm-text-bold-title-small {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.nm-text-bold-body-large {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nm-text-bold-body-medium {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nm-text-bold-body-small {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.nm-text-bold-label-medium {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nm-text-bold-label-small {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.nm-text-color-danger {
  color: #d61e1e;
}

.nm-text-link {
  color: #bd2e7e;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.nm-text-link:hover {
  color: #a6276e;
}
.nm-text-link:active {
  color: #b8528b;
}
.nm-text-link:visited {
  color: #bd2e7e;
}
.nm-text-link.disabled {
  cursor: none;
  pointer-events: none;
  color: #a6a6af;
}

.nm-text-disabled {
  color: #a6a6af;
}

.nm-tooltip-white {
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  white-space: pre-wrap;
  text-align: center;
  max-width: 360px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  background-color: white !important;
  color: black !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.nav-modal-panel {
  border-radius: 26px;
  overflow: hidden;
  border: 1px solid #d8dadc;
}

.localization-language-switcher-panel {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #d8dadc;
}

.team-member-container {
  margin-top: 16px;
}
.team-member-container img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 32px;
  flex-shrink: 0;
}
.team-member-container .user-info-container .nickname {
  font-size: 17px;
  color: #292e30;
}
.team-member-container .user-info-container .username {
  font-size: 11px;
  color: #757575;
}
.team-member-container .total-points {
  font-size: 17px;
  color: #292e30;
  font-weight: 600;
}

.team-member-expanded {
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  border: 1px solid rgba(117, 117, 117, 0.4);
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 12px 10px 15px;
}
.team-member-expanded .team-member-expanded-date {
  margin-left: auto;
  color: #757575;
  font-size: 12px;
}
.team-member-expanded .team-member-expanded-info {
  display: flex;
  align-items: center;
}
.team-member-expanded .team-member-expanded-avatar {
  flex-shrink: 0;
  height: 44px;
  width: 44px;
  margin-right: 32px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}
.team-member-expanded .team-member-expanded-nickname {
  font-size: 17px;
  color: #292e30;
  font-weight: 600;
}
.team-member-expanded .team-member-expanded-username {
  font-size: 12px;
  color: #757575;
}
.team-member-expanded .team-member-expanded-actions {
  display: flex;
  margin-left: auto;
  margin-top: 25px;
}
.team-member-expanded .team-member-expanded-actions .b-button {
  min-width: 0;
  color: #757575;
}

.content-section-container .content-sections-list {
  flex-grow: 1;
  overflow-y: auto;
}
.content-section-container .content-left-sidebar {
  display: flex;
  flex-direction: column;
}
.content-section-container .img-holder-margin {
  margin-bottom: 30px;
}
.content-section-container .back-link {
  color: #616161;
  text-decoration: none;
}

.edit-item-sidebar {
  position: relative;
  padding: 64px 20px 20px 20px;
}
.edit-item-sidebar .edit-item-sidebar-title {
  color: #292e30;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
.edit-item-sidebar .edit-item-sidebar-block-title {
  margin-bottom: 0px;
  margin-top: 30px;
  color: #292e30;
  font-size: 18px;
  font-weight: 600;
}
.edit-item-sidebar .edit-item-sidebar-form {
  display: flex;
  flex-direction: column;
}
.edit-item-sidebar .control-hint {
  font-size: 12px;
  line-height: 24px;
  color: #757575;
}
.edit-item-sidebar .image-cropper {
  display: block;
}
.edit-item-sidebar .save-button {
  position: absolute;
  top: 16px;
  right: 32px;
  font-size: 17px;
  cursor: pointer;
  background-color: transparent;
}
.edit-item-sidebar .save-button-disabled {
  cursor: no-drop;
}
.edit-item-sidebar .toggle-box {
  margin-top: 20px;
}
.edit-item-sidebar .toggle-box .mat-slide-toggle {
  width: 100%;
}
.edit-item-sidebar .toggle-box .mat-slide-toggle-bar {
  margin-left: auto;
}
.edit-item-sidebar .toggle-box .mat-slide-toggle-content {
  font-size: 13px;
  color: #757575;
}
.edit-item-sidebar .target-box {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.edit-item-sidebar .target-box-label {
  font-size: 13px;
  color: #757575;
}
.edit-item-sidebar .target-box-stepper {
  margin-left: auto;
}

.no-search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.no-search-result .mat-icon {
  width: 100px;
  height: 100px;
}
.no-search-result span {
  color: #757575;
  margin-top: 20px;
}

.no-uplinks-container {
  margin: 5rem;
}

.organization-title {
  margin-top: 20px;
  font-size: 16px;
}

.organization-name {
  text-transform: uppercase;
  font-weight: 600;
}

.ad-image-edit.ad-modify-section {
  padding-left: 15px;
}

.image-library-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-row-gap: 17px;
  grid-column-gap: 13px;
  padding-bottom: 20px;
  margin-right: 10px;
}
.image-library-selector .image-library-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 140px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 600;
  color: #363636;
  background-color: unset;
}
.image-library-selector .image-library-item img {
  max-width: 60px;
  max-height: 60px;
}
.image-library-selector .image-library-item-text {
  margin-top: 10px;
}

.image-library .image-item {
  height: 115px;
  transition: transform 200ms ease-out;
  cursor: pointer;
  position: relative;
}
.image-library .image-item:hover {
  transform: scale(1.1);
  z-index: 1;
}

.reset-mat-dialog .mat-dialog-container {
  padding: 0;
}

.transparent-backdrop {
  background-color: transparent;
}

.transparent-background .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
}

.visible-dialog-overflow .mat-dialog-container {
  overflow: visible;
}

.mat-menu-item {
  outline: none !important;
}

.search-autocomplete-options-container.search-autocomplete-options-container {
  box-shadow: none;
  border: 1px solid #d4d5d6;
  border-radius: 2px;
}

.spinner-color-main.mat-progress-spinner circle, .spinner-color-main.mat-spinner circle {
  stroke: #bd2e7e;
}

.spinner-color-cyan.mat-progress-spinner circle, .spinner-color-cyan.mat-spinner circle {
  stroke: #00b9b5;
}

.spinner-color-teal.mat-progress-spinner circle, .spinner-color-teal.mat-spinner circle {
  stroke: #00b9b5;
}

.spinner-color-pompadour.mat-progress-spinner circle, .spinner-color-pompadour.mat-spinner circle {
  stroke: #6c264d;
}

.spinner-color-light-gray.mat-progress-spinner circle, .spinner-color-light-gray.mat-spinner circle {
  stroke: #f2f6f6;
}

.spinner-color-dim-gray.mat-progress-spinner circle, .spinner-color-dim-gray.mat-spinner circle {
  stroke: #707070;
}

.spinner-color-dark-gray.mat-progress-spinner circle, .spinner-color-dark-gray.mat-spinner circle {
  stroke: #535353;
}

.spinner-color-brown.mat-progress-spinner circle, .spinner-color-brown.mat-spinner circle {
  stroke: #cab299;
}

.spinner-color-montana-gray.mat-progress-spinner circle, .spinner-color-montana-gray.mat-spinner circle {
  stroke: #363a3c;
}

.spinner-color-suva-grey.mat-progress-spinner circle, .spinner-color-suva-grey.mat-spinner circle {
  stroke: #8e8e8e;
}

.spinner-color-dark.mat-progress-spinner circle, .spinner-color-dark.mat-spinner circle {
  stroke: #292f31;
}

.spinner-color-black.mat-progress-spinner circle, .spinner-color-black.mat-spinner circle {
  stroke: #1b1f21;
}

.spinner-color-cod-grey.mat-progress-spinner circle, .spinner-color-cod-grey.mat-spinner circle {
  stroke: #292e30;
}

.spinner-color-white.mat-progress-spinner circle, .spinner-color-white.mat-spinner circle {
  stroke: #ffffff;
}

.validation-tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Poppins;
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid #e1e1e1;
  background-color: white;
}
.validation-tooltip div {
  padding-left: 24px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: left;
}
.validation-tooltip div.valid-item {
  background-image: url("/assets/images/validation-tooltip/checkmark.svg");
}
.validation-tooltip div.error-item {
  color: #e23600;
  background-image: url("/assets/images/validation-tooltip/crossmark.svg");
}
.validation-tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
}
.validation-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid;
  margin-top: -10px;
}
.validation-tooltip.top:before {
  border-color: #e1e1e1 transparent;
  border-width: 10px 10px 0;
  left: 45%;
  bottom: -10px;
}
.validation-tooltip.top:after {
  border-color: #ffffff transparent;
  border-width: 10px 10px 0;
  left: 45%;
  bottom: -8px;
}
.validation-tooltip.right:before {
  border-color: transparent #e1e1e1;
  border-width: 11px 11px 11px 0;
  top: 50%;
  left: -11.5px;
  margin-top: -11px;
}
.validation-tooltip.right:after {
  border-color: transparent #ffffff;
  border-width: 10px 10px 10px 0;
  top: 50%;
  left: -10px;
}

.commissions-info-box {
  max-width: 720px;
}
.commissions-info-box .commissions-info-box-title {
  color: #202020;
  font-size: 17px;
  font-weight: 600;
}
.commissions-info-box .commissions-info-box-description {
  color: #696c65;
  font-size: 14px;
}
.commissions-info-box .commissions-info-box-description a {
  color: #d10382;
  text-decoration: underline;
}
.commissions-info-box .commissions-info-box-action-button {
  margin-top: 15px;
}

.mat-tab-group {
  font-family: Poppins,  sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Poppins,  sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #bd2e7e;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #bd2e7e;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e25874;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #292e30;
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #bd2e7e;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #bd2e7e;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e25874;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #292e30;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(41, 46, 48, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: #292e30;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #292e30;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #292e30;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #bd2e7e;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #bd2e7e;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e25874;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e25874;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #bd2e7e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #bd2e7e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e25874;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #bd2e7e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #bd2e7e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e25874;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e25874;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e25874;
  --mat-mdc-button-ripple-color: rgba(226, 88, 116, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #bd2e7e;
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #bd2e7e;
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e25874;
  --mat-mdc-button-persistent-ripple-color: #e25874;
  --mat-mdc-button-ripple-color: rgba(226, 88, 116, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-option {
  color: rgba(41, 46, 48, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(41, 46, 48, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(41, 46, 48, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bd2e7e;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bd2e7e;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e25874;
}

.mat-optgroup-label {
  color: rgba(41, 46, 48, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(41, 46, 48, 0.38);
}

.mat-ripple-element {
  background-color: rgba(41, 46, 48, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(41, 46, 48, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #bd2e7e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #bd2e7e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #bd2e7e;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #bd2e7e;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #bd2e7e;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #bd2e7e;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e25874;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e25874;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(41, 46, 48, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(41, 46, 48, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(41, 46, 48, 0.54);
}

.mat-progress-bar-background {
  fill: #ebc7db;
}

.mat-progress-bar-buffer {
  background-color: #ebc7db;
}

.mat-progress-bar-fill::after {
  background-color: #bd2e7e;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ebc7db;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ebc7db;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #bd2e7e;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f4d2d9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f4d2d9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e25874;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #bd2e7e;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #bd2e7e;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e25874;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(41, 46, 48, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(41, 46, 48, 0.38);
}

.mat-input-element {
  caret-color: #bd2e7e;
}
.mat-input-element::placeholder {
  color: rgba(41, 46, 48, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(41, 46, 48, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(41, 46, 48, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(41, 46, 48, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #bd2e7e;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e25874;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e25874;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(41, 46, 48, 0.38);
}

.mat-form-field-label {
  color: rgba(41, 46, 48, 0.6);
}

.mat-hint {
  color: rgba(41, 46, 48, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #bd2e7e;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #bd2e7e;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e25874;
}

.mat-focused .mat-form-field-required-marker {
  color: #bd2e7e;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #bd2e7e;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #bd2e7e;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e25874;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #bd2e7e;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #bd2e7e;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e25874;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e25874;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e25874;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e25874;
}

.mat-error {
  color: #e25874;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(41, 46, 48, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(41, 46, 48, 0.54);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, 0%, 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, 0%, 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(41, 46, 48, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(41, 46, 48, 0.02);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(41, 46, 48, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #bd2e7e;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #bd2e7e;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e25874;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e25874;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(41, 46, 48, 0.38);
}
.mat-select-value {
  color: rgba(41, 46, 48, 0.87);
}

.mat-select-placeholder {
  color: rgba(41, 46, 48, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(41, 46, 48, 0.38);
}

.mat-select-arrow {
  color: rgba(41, 46, 48, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #bd2e7e;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #bd2e7e;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e25874;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e25874;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(41, 46, 48, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(41, 46, 48, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #bd2e7e;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #bd2e7e;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e25874;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(41, 46, 48, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: #292e30;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #bd2e7e;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #bd2e7e;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e25874;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(41, 46, 48, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(41, 46, 48, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(41, 46, 48, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(41, 46, 48, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(41, 46, 48, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #292e30;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #bd2e7e;
  color: #292e30;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #292e30;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e25874;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #bd2e7e;
  color: #292e30;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #292e30;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(41, 46, 48, 0.1);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #bd2e7e;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(189, 46, 126, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #bd2e7e;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #bd2e7e;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(189, 46, 126, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #bd2e7e;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e25874;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(226, 88, 116, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e25874;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #292e30;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(41, 46, 48, 0.38);
}

.mat-radio-outer-circle {
  border-color: rgba(41, 46, 48, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd2e7e;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #bd2e7e;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd2e7e;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #bd2e7e;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e25874;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e25874;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(41, 46, 48, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(41, 46, 48, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(41, 46, 48, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: #292e30;
}

.mat-slider-track-background {
  background-color: rgba(41, 46, 48, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #bd2e7e;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: #292e30;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(189, 46, 126, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #bd2e7e;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #292e30;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(189, 46, 126, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e25874;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(226, 88, 116, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(41, 46, 48, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(41, 46, 48, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(41, 46, 48, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(41, 46, 48, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(41, 46, 48, 0.38);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(41, 46, 48, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(41, 46, 48, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(41, 46, 48, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(41, 46, 48, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(41, 46, 48, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(41, 46, 48, 0.7), rgba(41, 46, 48, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(41, 46, 48, 0.7), rgba(41, 46, 48, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(41, 46, 48, 0.7), rgba(41, 46, 48, 0.7) 2px, transparent 0, transparent);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(41, 46, 48, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(41, 46, 48, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(41, 46, 48, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item {
  color: rgba(41, 46, 48, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(41, 46, 48, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(41, 46, 48, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(41, 46, 48, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(41, 46, 48, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(41, 46, 48, 0.54);
  border-right: 2px solid rgba(41, 46, 48, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(41, 46, 48, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(41, 46, 48, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid;
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid;
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(41, 46, 48, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(41, 46, 48, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(41, 46, 48, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(41, 46, 48, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(235, 192, 216, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #bd2e7e;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: #292e30;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(235, 192, 216, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #bd2e7e;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #292e30;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(246, 205, 213, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e25874;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(235, 192, 216, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #bd2e7e;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: #292e30;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(41, 46, 48, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: #292e30;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #292e30;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #292e30;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(235, 192, 216, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #bd2e7e;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #292e30;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(41, 46, 48, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #292e30;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #292e30;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #292e30;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(246, 205, 213, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(41, 46, 48, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e25874;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #bd2e7e;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

.mat-header-cell {
  color: rgba(41, 46, 48, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(41, 46, 48, 0.87);
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #bd2e7e;
  --mat-badge-text-color: #292e30;
  --mat-badge-disabled-state-background-color: #292e30;
  --mat-badge-disabled-state-text-color: rgba(41, 46, 48, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #bd2e7e;
  --mat-badge-text-color: #292e30;
}

.mat-badge-warn {
  --mat-badge-background-color: #e25874;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(41, 46, 48, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(41, 46, 48, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(41, 46, 48, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: #292e30;
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(41, 46, 48, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(41, 46, 48, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: #292e30;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(41, 46, 48, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #292e30;
  --mat-datepicker-calendar-date-selected-state-background-color: #bd2e7e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(189, 46, 126, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #292e30;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(189, 46, 126, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(189, 46, 126, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #bd2e7e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(189, 46, 126, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(41, 46, 48, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(41, 46, 48, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(41, 46, 48, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(41, 46, 48, 0.54);
  --mat-datepicker-calendar-header-text-color: rgba(41, 46, 48, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(41, 46, 48, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(41, 46, 48, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(41, 46, 48, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(41, 46, 48, 0.38);
  --mat-datepicker-range-input-separator-color: rgba(41, 46, 48, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(41, 46, 48, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(41, 46, 48, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(41, 46, 48, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #292e30;
  --mat-datepicker-calendar-date-selected-state-background-color: #bd2e7e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(189, 46, 126, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #292e30;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(189, 46, 126, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(189, 46, 126, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(189, 46, 126, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e25874;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(226, 88, 116, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(226, 88, 116, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(226, 88, 116, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(226, 88, 116, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #bd2e7e;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #e25874;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(41, 46, 48, 0.87);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: #292e30;
  --mat-expansion-header-text-color: rgba(41, 46, 48, 0.87);
  --mat-expansion-header-description-color: rgba(41, 46, 48, 0.54);
  --mat-expansion-header-indicator-color: rgba(41, 46, 48, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #bd2e7e;
}
.mat-icon.mat-accent {
  --mat-icon-color: #bd2e7e;
}
.mat-icon.mat-warn {
  --mat-icon-color: #e25874;
}

html {
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(41, 46, 48, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(41, 46, 48, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #292e30;
  --mat-stepper-header-selected-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-selected-state-icon-foreground-color: #292e30;
  --mat-stepper-header-done-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-done-state-icon-foreground-color: #292e30;
  --mat-stepper-header-edit-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-edit-state-icon-foreground-color: #292e30;
  --mat-stepper-container-color: white;
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(41, 46, 48, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(41, 46, 48, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(41, 46, 48, 0.87);
  --mat-stepper-header-error-state-label-text-color: #e25874;
  --mat-stepper-header-icon-background-color: rgba(41, 46, 48, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #e25874;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #292e30;
  --mat-stepper-header-selected-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-selected-state-icon-foreground-color: #292e30;
  --mat-stepper-header-done-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-done-state-icon-foreground-color: #292e30;
  --mat-stepper-header-edit-state-icon-background-color: #bd2e7e;
  --mat-stepper-header-edit-state-icon-foreground-color: #292e30;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e25874;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e25874;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e25874;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #8b8e8f;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(41, 46, 48, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #bd2e7e;
  --mat-toolbar-container-text-color: #292e30;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #bd2e7e;
  --mat-toolbar-container-text-color: #292e30;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #e25874;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(41, 46, 48, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #bd2e7e;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #bd2e7e;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e25874;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #292e30;
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #bd2e7e;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #bd2e7e;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e25874;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #292e30;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(41, 46, 48, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #292e30;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #bd2e7e;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #bd2e7e;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e25874;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e25874;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #bd2e7e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #bd2e7e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e25874;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #bd2e7e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #bd2e7e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e25874;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #bd2e7e;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e25874;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e25874;
  --mat-mdc-button-ripple-color: rgba(226, 88, 116, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #bd2e7e;
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #bd2e7e;
  --mat-mdc-button-persistent-ripple-color: #bd2e7e;
  --mat-mdc-button-ripple-color: rgba(189, 46, 126, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e25874;
  --mat-mdc-button-persistent-ripple-color: #e25874;
  --mat-mdc-button-ripple-color: rgba(226, 88, 116, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.nm-material-theme .mat-form-field {
  display: block;
  width: 100%;
  min-width: 0;
  margin: 4px 0;
  color: rgba(0, 0, 0, 0.1215686275);
}
.nm-material-theme .mat-form-field.mat-form-field-has-label {
  padding-top: 32px;
}
.nm-material-theme .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.nm-material-theme .mat-form-field.nm-no-hint .mat-form-field-wrapper {
  padding-bottom: 0;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0;
  padding: 0 8px;
  border-radius: 5px;
  background: #ffffff;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline {
  outline-width: 1px;
  top: 0;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
  outline-width: 1px;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  outline-width: 1px;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled {
  pointer-events: none;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  opacity: 0.38;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: initial !important;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-label-wrapper .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 1px;
  opacity: 0.3;
  font-size: 14px;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix + .mat-form-field-infix .mat-form-field-label-wrapper {
  margin-left: -59px;
}
.nm-material-theme .mat-form-field .mat-form-field-infix {
  padding: 8px;
  border-top: 0;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #292e30;
}
.nm-material-theme .mat-form-field .mat-form-field-infix .mat-select .mat-select-value {
  box-sizing: border-box;
  padding-right: 32px;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 1px;
  opacity: 0.3;
  font-size: 14px;
}
.nm-material-theme .mat-form-field .mat-form-field-label-wrapper {
  margin-left: -8px;
  top: -32px;
  padding-top: 32px;
  font-weight: 600;
  color: #292e30;
}
.nm-material-theme .mat-form-field .mat-form-field-label-wrapper .mat-form-field-label {
  color: inherit;
}
.nm-material-theme .mat-form-field.mat-focused .mat-form-field-label-wrapper .mat-form-field-label {
  color: #bd2e7e;
}
.nm-material-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label-wrapper .mat-form-field-label {
  color: #e25874;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.nm-material-theme .mat-form-field .mat-form-field-appearance-outline.mat-form-field-can-float,
.nm-material-theme .mat-form-field .mat-input-server:focus,
.nm-material-theme .mat-form-field + .mat-form-field-label-wrapper,
.nm-material-theme .mat-form-field .mat-form-field-label {
  transform: translateY(-24px) scale(1);
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper,
.nm-material-theme .mat-form-field .mat-form-field-hint-wrapper {
  margin-top: 0;
  padding: 0;
}
.nm-material-theme .mat-form-field .mat-error,
.nm-material-theme .mat-form-field .mat-hint {
  font-size: 12px;
  line-height: 24px;
}
.nm-material-theme .mat-form-field .mat-input-element {
  font-size: 14px;
}
.nm-material-theme .mat-form-field .mat-input-element::placeholder {
  color: #535353;
  opacity: 0.5;
}
.nm-material-theme .mat-form-field .mat-input-element:-webkit-autofill, .nm-material-theme .mat-form-field .mat-input-element:-webkit-autofill:hover, .nm-material-theme .mat-form-field .mat-input-element:-webkit-autofill:focus, .nm-material-theme .mat-form-field .mat-input-element:-webkit-autofill:active {
  box-shadow: 0 0 0 40px #fff inset;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex {
  position: relative;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex .mat-select-arrow-wrapper {
  transform: translateY(-5px);
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex .mat-select-arrow-wrapper .mat-select-arrow {
  margin-top: 8px;
  margin-right: 0;
  margin-left: -11px;
  border: solid;
  border-width: 0 1px 1px 0;
  height: 10px;
  width: 10px;
  box-sizing: border-box;
  transform-origin: 6px 6px;
  transform: rotate(45deg);
  transition: 600ms cubic-bezier(0.25, 0.8, 0.25, 1) 0ms;
  transition-property: transform, border-color;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex::after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: #000000;
  opacity: 0.12;
  position: absolute;
  right: 40px;
  top: 0;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex:hover::after {
  width: 2px;
  height: 100%;
  opacity: 0.87;
  background-color: #000000;
  transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1) 0ms;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-flex:hover .mat-select-arrow-wrapper .mat-select-arrow {
  border-color: #000000;
  border-width: 0 2px 2px 0;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select.mat-focused .mat-form-field-flex::after {
  width: 2px;
  height: 100%;
  background-color: #bd2e7e;
  opacity: 1;
  transition: none;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select.mat-focused .mat-form-field-flex .mat-select-arrow-wrapper .mat-select-arrow {
  border-color: #bd2e7e;
  border-width: 0 2px 2px 0;
  transform: rotate(225deg);
  transition-property: transform;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex::after {
  width: 2px;
  height: 100%;
  background-color: #e25874;
  opacity: 1;
  transition: none;
}
.nm-material-theme .mat-form-field.mat-form-field-appearance-outline.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex .mat-select-arrow-wrapper .mat-select-arrow {
  border-color: #e25874;
  border-width: 0 2px 2px 0;
}
.nm-material-theme .mat-form-field .mat-select-trigger {
  min-height: inherit;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip-list-wrapper {
  overflow: auto;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip.mat-standard-chip {
  font-size: 12px;
  cursor: pointer;
  background-color: #ffffff;
  color: #292e30;
  padding: 0 16px;
  min-height: 30px;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip.mat-standard-chip::after {
  background-color: #ffffff;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip.mat-standard-chip.mat-primary {
  background-color: #bd2e7e;
  color: #ffffff;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip.mat-standard-chip.mat-accent {
  background-color: #bd2e7e;
  color: #ffffff;
}
.nm-material-theme .mat-form-field .mat-select-trigger .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #ffffff;
  opacity: 1;
}

.nm-select-dropdown-panel {
  margin-top: 40px;
}

.mat-form-field-autofill-control {
  color: #292e30;
}

.mat-button-base.mat-button, .mat-button-base.mat-flat-button, .mat-button-base.mat-raised-button {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
}
.mat-button-base.mat-stroked-button {
  height: 40px;
  line-height: 38px;
  border-radius: 4px;
  font-weight: 600;
  background: white;
  font-size: 12px;
}
.mat-button-base.mat-icon-button {
  height: 40px;
  border-radius: 4px;
}

.mat-button.mat-button-disabled,
.mat-raised-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-flat-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled {
  pointer-events: none;
  filter: opacity(0.4);
}
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  background-color: transparent;
}

.mat-stroked-button.mat-button-disabled {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled {
  color: #bd2e7e;
}
.mat-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled {
  color: #bd2e7e;
}
.mat-button.mat-cyan.mat-button-disabled,
.mat-icon-button.mat-cyan.mat-button-disabled,
.mat-stroked-button.mat-cyan.mat-button-disabled {
  color: #00b9b5;
}
.mat-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled {
  color: #e25874;
}

.mat-button:hover.mat-primary,
.mat-stroked-button:hover.mat-primary {
  background-color: rgba(189, 46, 126, 0.1);
}
.mat-button:hover.mat-accent,
.mat-stroked-button:hover.mat-accent {
  background-color: rgba(189, 46, 126, 0.1);
}
.mat-button:hover.mat-cyan,
.mat-stroked-button:hover.mat-cyan {
  background-color: rgba(0, 185, 181, 0.1);
}
.mat-button:hover.mat-warn,
.mat-stroked-button:hover.mat-warn {
  background-color: rgba(226, 88, 116, 0.1);
}

.mat-icon-button:hover {
  opacity: 80%;
}

.mat-raised-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-primary.mat-button-disabled {
  color: #ffffff;
  background-color: #bd2e7e;
}
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled {
  color: #ffffff;
  background-color: #bd2e7e;
}
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled {
  color: #ffffff;
  background-color: #e25874;
}
.mat-raised-button:hover,
.mat-flat-button:hover {
  background-color: rgba(41, 46, 48, 0.04);
}
.mat-raised-button:hover.mat-primary,
.mat-flat-button:hover.mat-primary {
  background-color: rgba(189, 46, 126, 0.8);
}
.mat-raised-button:hover.mat-accent,
.mat-flat-button:hover.mat-accent {
  background-color: rgba(189, 46, 126, 0.8);
}
.mat-raised-button:hover.mat-cyan,
.mat-flat-button:hover.mat-cyan {
  background-color: rgba(0, 185, 181, 0.8);
}
.mat-raised-button:hover.mat-warn,
.mat-flat-button:hover.mat-warn {
  background-color: rgba(226, 88, 116, 0.8);
}

.nm-underlined-button.mat-button-base.mat-stroked-button {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
  min-width: auto;
}

.nm-material-theme .mat-chip,
.mat-standard-chip {
  height: auto;
  font-size: 12px;
  cursor: pointer;
  background-color: #ffffff;
  color: #292e30;
  padding: 0 16px;
  min-height: 30px;
}
.nm-material-theme .mat-chip.mat-chip-selected.mat-primary,
.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #bd2e7e;
  color: #ffffff;
}
.nm-material-theme .mat-chip.mat-chip-selected.mat-accent,
.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #bd2e7e;
  color: #ffffff;
}
.nm-material-theme .mat-chip.mat-chip-disabled,
.mat-standard-chip.mat-chip-disabled {
  pointer-events: none;
}
.nm-material-theme .mat-chip.nm-chip-grey,
.mat-standard-chip.nm-chip-grey {
  color: #707070;
  background: #f2f6f6;
}
.nm-material-theme .mat-chip.nm-chip-grey.mat-standard-chip .mat-chip-remove.mat-icon,
.mat-standard-chip.nm-chip-grey.mat-standard-chip .mat-chip-remove.mat-icon {
  opacity: 1;
  font-size: 12px;
  line-height: 18px;
  min-width: 18px;
  text-align: center;
  background: white;
  border-radius: 50%;
}

.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-cyan .mat-checkbox-background,
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-cyan .mat-checkbox-frame {
  background-color: #00b9b5;
  border-color: #00b9b5;
}
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-primary .mat-checkbox-frame {
  background-color: #bd2e7e;
  border-color: #bd2e7e;
}
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-frame {
  background-color: #bd2e7e;
  border-color: #bd2e7e;
}
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.nm-material-theme .mat-checkbox.mat-checkbox-checked.mat-warn .mat-checkbox-frame {
  background-color: #e25874;
  border-color: #e25874;
}
.nm-material-theme .mat-checkbox.mat-checkbox-disabled .mat-checkbox-background,
.nm-material-theme .mat-checkbox.mat-checkbox-disabled .mat-checkbox-frame {
  opacity: 0.54;
}

.nm-material-theme .mat-radio-button.mat-cyan.mat-radio-checked .mat-radio-inner-circle {
  background-color: #00b9b5;
}
.nm-material-theme .mat-radio-button.mat-cyan.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00b9b5;
}
.nm-material-theme .mat-radio-button.mat-cyan .mat-ripple-element {
  background-color: #00b9b5 !important;
}
.nm-material-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-inner-circle {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd2e7e;
}
.nm-material-theme .mat-radio-button.mat-primary .mat-ripple-element {
  background-color: #bd2e7e !important;
}
.nm-material-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd2e7e;
}
.nm-material-theme .mat-radio-button.mat-accent .mat-ripple-element {
  background-color: #bd2e7e !important;
}
.nm-material-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-inner-circle {
  background-color: #e25874;
}
.nm-material-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e25874;
}
.nm-material-theme .mat-radio-button.mat-warn .mat-ripple-element {
  background-color: #e25874 !important;
}
.nm-material-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
.nm-material-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  opacity: 0.54;
}

.nm-material-theme .mat-slider.mat-primary .mat-slider-track-background {
  background-color: #bd2e7e;
  opacity: 0.3;
}
.nm-material-theme .mat-slider.mat-primary .mat-slider-track-fill {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-primary .mat-slider-thumb {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: #ffffff;
}
.nm-material-theme .mat-slider.mat-primary:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
  transform: none;
  outline: 10px solid rgba(103, 80, 164, 0.12);
  border-radius: 50%;
}
.nm-material-theme .mat-slider.mat-accent .mat-slider-track-background {
  background-color: #bd2e7e;
  opacity: 0.3;
}
.nm-material-theme .mat-slider.mat-accent .mat-slider-track-fill {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-accent .mat-slider-thumb {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #bd2e7e;
}
.nm-material-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #ffffff;
}
.nm-material-theme .mat-slider.mat-accent:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
  transform: none;
  outline: 10px solid rgba(103, 80, 164, 0.12);
  border-radius: 50%;
}
.nm-material-theme .mat-slider.mat-slider-horizontal {
  width: 100%;
}
.nm-material-theme .mat-slider.mat-slider-horizontal .mat-slider-thumb-label {
  top: -46px;
}
.nm-material-theme .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  height: 4px;
}
.nm-material-theme .mat-slider.mat-slider-horizontal .mat-slider-wrapper .mat-slider-track-wrapper {
  height: 4px;
  border-radius: 2px;
}
.nm-material-theme .mat-slider.mat-slider-horizontal .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-background,
.nm-material-theme .mat-slider.mat-slider-horizontal .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  height: 4px;
}

.nm-material-theme .mat-tab-group .mat-tab-label {
  opacity: 1;
  border-right: 1px solid #707070;
  font-size: 12px;
  height: 32px;
  font-weight: 600;
}
.nm-material-theme .mat-tab-group .mat-tab-label:first-child {
  border-left: 1px solid #707070;
}
.nm-material-theme .mat-tab-group .mat-tab-label:hover {
  opacity: 0.8;
}
.nm-material-theme .mat-tab-group .mat-tab-label:focus {
  background-color: transparent;
}
.nm-material-theme .mat-tab-group .mat-tab-label.mat-tab-disabled:hover {
  opacity: 1;
}
.nm-material-theme .mat-tab-group.mat-cyan .mat-tab-label-active {
  color: #00b9b5;
}
.nm-material-theme .mat-tab-group.mat-primary .mat-tab-label-active {
  color: #bd2e7e;
}
.nm-material-theme .mat-tab-group.mat-accent .mat-tab-label-active {
  color: #bd2e7e;
}
.nm-material-theme .mat-tab-group.mat-warn .mat-tab-label-active {
  color: #e25874;
}
.nm-material-theme .mat-tab-group .mat-tab-header {
  border: 0;
  margin: 0 0 16px;
}
.nm-material-theme .mat-tab-group .mat-ink-bar {
  display: none;
}

.mat-icon.mat-cyan {
  color: #00b9b5;
}
.mat-icon.mat-primary {
  color: #bd2e7e;
}
.mat-icon.mat-accent {
  color: #bd2e7e;
}
.mat-icon.mat-warn {
  color: #e25874;
}

.nm-modal-close-button.mat-button-base.mat-button, .nm-modal-close-button-white.mat-button-base.mat-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  min-width: auto;
}
.nm-modal-close-button.mat-button-base.mat-button:hover, .nm-modal-close-button-white.mat-button-base.mat-button:hover {
  background-image: url("/assets/images/upline/general/close-circle-active.svg");
}
.nm-modal-close-button.mat-button-base.mat-button {
  background-image: url("/assets/images/upline/general/close-circle.svg");
}
.nm-modal-close-button-white.mat-button-base.mat-button {
  background-image: url("/assets/images/upline/general/close-circle-white.svg");
}

.nm-modal-secondary-footer-action.mat-button-base.mat-button {
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.mat-tooltip {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}